export default {
  theme: {
    fontFamily: {
      'font-default': ['Sora', 'sans-serif'],
    },
    extend: {
      colors: {
        // brand colors
        'primary-blue-disabled': '#C4BED3',
        'primary-blue-medium': '#9484BF',
        'primary-blue-base': '#42395E',
        'primary-blue-dark': '#221C35',
        'secondary-lemon-brand': '#97D700',
        'secondary-lemon-medium': '#75D991',

        // neutral colors
        white: '#FFFFFF',
        'neutral-gray-low': '#E8F0EE',
        'neutral-gray-disabled': '#B5B5B5',
        'neutral-gray-medium': '#7A7A7A',

        // feedback colors
        'feedback-blue-high': '#027CBE',
        'feedback-blue-low': '#9ACBE5',
        'feedback-green-high': '#26BF83',
        'feedback-green-low': '#A8E5CD',
        'feedback-orange-high': '#FF5C39',
        'feedback-orange-low': '#FF937C',
        'feedback-red-high': '#D50032',
        'feedback-red-low': '#F1C3C3',
        'feedback-yellow': '#e6cf01',
        'feedback-yellow-low': '#F4E344',

        // shadows
        'header-primary-blue-dark': 'rgba(34, 28, 53, 0.08)',

        // cores do protótipo
        blue1: '#22588b',
        blue2: '#2275c0',
        'gray-5': 'rgba(0, 0, 0, 0.56)',
        'gray-8': 'rgba(0, 0, 0, 0.8)',
        'status-success-1': '#168614',
        'status-success-2': 'rgba(22, 134, 20, 0.2)',
        'status-danger-1': '#e03634',
        'status-danger-2': 'rgba(224, 54, 52, 0.2)',
        'status-warning-1': '#fb8c00',
        'snackbar-bg-success': 'rgba(76, 175, 80, 0.12)',
        'snackbar-bg-error': 'rgba(255, 82, 82, 0.12)',
        'snackbar-bg-warning': 'rgba(251, 140, 0, 0.12)',
        'notification-active-class': '#e3f1f8',
        'progressbar-background': '#D9D9D9',

        // states
        'state-medium-select': '#93959F',

        // cores unijs
        'white-bis': '#fafafa',
        light: '#f5f5f5',
        'grey-lighter': '#dbdbdb',
        'grey-light': '#b5b5b5',
        grey: '#7a7a7a',
        'grey-darker': '#676a6c',
        'grey-dark': '#4a4a4a',
        'black-ter': '#242424',
        'black-bis': '#121212',
        black: '#0a0a0a',
        primary: '#027cbe',
        success: '#00995d',
        warning: '#eabf2f',
        danger: '#c13018',
        'blue-menu-1': '#205286',
        'blue-menu-2': '#4685c0',
        'blue-menu-3': '#132634',

        // botões
        health: '#28a2e4',
        'health-escuro': '#027cbe',
        pomegranade: '#d50032',

        // base
        'azul-base': '#203f57',
        'azul-escuro': '#132634',
        'kiwi-claro': '#00995d',

        // fundo e texto
        gray2: '#f4f4f4',
        gray3: '#dedede',
        gray4: '#cccccc',
        gray5: '#999999',
        gray6: '#666666',
        gray7: '#333333',

        // status
        azul: '#0c59ef',
        verde: '#168614',
        amarelo: '#ffcb08',
        laranja: '#f47920',
        vermelho: '#ff0000',

        // cores DS
        // cores neutras | escala de cinza
        'neutral-low-pure': '#121212',
        'neutral-low-dark': '#3d3d3d',
        'neutral-low-medium': '#8e8e8e',
        'neutral-low-light': '#c8c8c8',
        'neutral-high-dark': '#dddddd',
        'neutral-high-medium': '#eaeaea',
        'neutral-high-light': '#f4f4f4',
        'neutral-high-pure': '#ffffff',

        // cores da marca
        'branding-primary-lemon': '#97d700',
        'branding-primary-kiwi': '#00bf6f',
        'branding-primary-tech': '#221c35',
        'branding-primary-tech-suave': '#39334a',
        'branding-secundary-health': '#9be3bf',
        'branding-secondary-cidra': '#d4ec8e',
        'branding-secondary-toranja': '#ff5c39',
        'branding-secondary-roma': '#d50032',

        // cores de feedback
        'feedback-informative-pure': '#027cbe',
        'feedback-informative-light': '#9acbe5',
        'feedback-success-pure': '#26bf83',
        'feedback-success-light': '#a8e5cd',
        'feedback-warning-pure': '#f49f20',
        'feedback-warning-light': '#fbd9a6',
        'feedback-error-pure': '#ce3737',
        'feedback-error-light': '#f1c3c3',

        // cores vuetify
        // cores feedback
        'red-accent-2': '#ff5252',

        'toast-bg-success': '#e0f7ed',
        'toast-text-success': '#00bf6f',
        'toast-bg-warning': '#fef1e0',
        'toast-text-warning': '#fb8c00',
        'toast-bg-info': '#e4f2fd',
        'toast-text-info': '#027cbe',
        'toast-bg-error': '#fae0e6',
        'toast-text-error': '#d50032',
      },
    },
  },
  variants: {
    extend: {},
  },
};
