const forms = {
  applicationKey: '',
  content: '',
  version: '',
  createAt: '',
  id: null,
  enabled: false,
};

export default () => ({
  loading: false,
  loadingTable: false,
  loadingTableHistorico: false,
  aplicacoes: [],
  aplicacao: {},
  listaConsentimento: {},
  isEditMode: false,
  isFormDataDirty: false,
  termoVigente: { ...forms },
  originalTermoVigente: { ...forms },
  historicoConsentimentos: [],
  totalPages: 0,
  totalElements: 0,
  enabledButtons: false,
});
