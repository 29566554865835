import { api, zBffApi, zWorkspaceApi } from '@/api';
import { createCommandService } from '@/services';

const loadUserProfileService = async ({ usuarioId, onSuccess, onCustomError }) =>
  await createCommandService({
    method: 'get',
    uri: `${zBffApi}/dados-usuario/${usuarioId}`,
    onSuccess,
    onCustomError,
  });

const saveWorkspaceAttributes = async ({ usuarioId, payload, onSuccess, onCustomError }) => {
  await createCommandService({
    method: 'put',
    payload,
    uri: `${zWorkspaceApi}/usuario/${usuarioId}/atributos`,
    onSuccess,
    onCustomError,
  });
};

const refreshTokenApi = async ({ payload, onSuccess }) => {
  await createCommandService({
    method: 'post',
    payload,
    uri: `${api}/login/refreshtoken`,
    onSuccess,
  });
};

export { loadUserProfileService, saveWorkspaceAttributes, refreshTokenApi };
