import Vue from 'vue';
import initialState from './state';
import { initialStateCredentials, initialStatePayload } from '../constants';

export default {
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { object, property, value }) {
    Vue.set(object ? state.payload[object] : state.payload, property, value);
  },
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_PAYLOAD(state) {
    Object.assign(state.payload, initialStatePayload, {
      integrationCredential: { ...initialStateCredentials },
    });

    Object.assign(state.initialState, initialStatePayload, {
      integrationCredential: { ...initialStateCredentials },
    });
  },
  RESET_CREDENTIALS(state) {
    Object.assign(state.payload.integrationCredential, initialStateCredentials);
    Object.assign(state.initialState.integrationCredential, initialStateCredentials);
  },
};
