import Vue from 'vue';
import router from '@/router';

import {
  getApplicationByKey,
  getApplications,
  getApplicationPermissions,
  getApplicationById,
  createApplicationService,
  deleteApplicationService,
  updateApplicationService,
} from '@/views/application/service';

const loadApplicationByKey = async ({ commit }, key) => {
  if (!key || key?.length === 0) {
    commit('SET_APPLICATION', {});
    commit('SET_APPLICATION_KEY', '');

    return true;
  }

  commit('SET_APPLICATION_KEY', key);

  await getApplicationByKey({
    key,
    onSuccess: ({ data }) => {
      commit('SET_APPLICATION', data);
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicação por chave');
    },
  });
};

const loadPagedApplications = async ({ commit }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getApplications({
    query,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'applications',
        payload: data?.content,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        payload: data?.totalElements,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalPages',
        payload: data?.totalPages,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const loadApplications = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getApplications({
    query: null,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'applications',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const loadApplicationById = async ({ commit }, id) => {
  await getApplicationById({
    id,
    onSuccess: async ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'application',
        payload: data,
      });

      await loadApplicationPermissions(data?.key);
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicação');
    },
  });
};

const loadApplicationPermissions = async ({ commit }, applicationId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingPermissions',
    payload: true,
  });

  await getApplicationPermissions({
    applicationId,
    onSuccess: ({ data }) => {
      const order = { view: 1, create: 2, edit: 3, delete: 4 };

      data.forEach(({ features }) => {
        features.forEach(({ permissions }) => {
          permissions.sort((a, b) => order[a.type] - order[b.type]);
        });
      });

      commit('GENERIC_MUTATION', {
        property: 'applicationPermissions',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response.data?.detail && !Array.isArray(response.data?.detail)
          ? response.data?.detail
          : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao buscar permissões da aplicação selecionada',
      );
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingPermissions',
        payload: false,
      });
    },
  });
};

const createApplication = async ({ getters, commit }, saveAndNew) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadApplication } = getters;

  await createApplicationService({
    payload: payloadApplication,
    onSuccess: () => {
      router.push(
        !saveAndNew
          ? { name: 'applicationQuery' }
          : { name: 'applicationEdit', params: { id: payloadApplication?.id } },
      );

      Vue.$toast.success('Aplicação criada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao criar aplicação');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const updateApplication = async ({ getters, commit }, saveAndNew) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadApplication } = getters;

  await updateApplicationService({
    payload: payloadApplication,
    onSuccess: () => {
      !saveAndNew && router.push({ name: 'applicationQuery' });

      Vue.$toast.success('Aplicação alterada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao atualizar aplicação');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const deleteApplication = async ({ state, commit, dispatch }, { id, query }) => {
  await deleteApplicationService({
    id,
    onSuccess: () => {
      Vue.$toast.success('Aplicação excluída com sucesso.');

      if (state.permissions.length === 1) {
        router.replace({
          name: 'permissionQuery',
          query: {
            ...query,
            page: query.page,
          },
        });
      }

      dispatch('loadApplications', query);

      commit('RESET_FORM_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao excluir aplicação');
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

const resetStateAppPermissions = ({ commit }) => {
  commit('RESET_APP_PERMISSIONS_STATE');
};

export default {
  loadApplicationByKey,
  loadApplications,
  loadApplicationById,
  loadApplicationPermissions,
  loadPagedApplications,
  createApplication,
  updateApplication,
  deleteApplication,
  updateField,
  resetState,
  resetStateAppPermissions,
};
