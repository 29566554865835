/* eslint-disable no-param-reassign */
import Vue from 'vue';

const toastService = new Vue({
  data() {
    return {
      options: {
        show: false,
        message: '',
        type: '',
        icon: '',
        to: null,
      },
    };
  },
  methods: {
    success(message, to = null) {
      this.options.show = true;
      this.options.message = message;
      this.options.type = 'success';
      this.options.icon = 'mdi-check-circle-outline';
      this.options.to = to;
    },
    error(message, to = null) {
      this.options.show = true;
      this.options.message = message;
      this.options.type = 'error';
      this.options.icon = 'mdi-alert-circle-outline';
      this.options.to = to;
    },
    info(message, to = null) {
      this.options.show = true;
      this.options.message = message;
      this.options.type = 'info';
      this.options.icon = 'mdi-bell-outline';
      this.options.to = to;
    },
    warning(message, to = null) {
      this.options.show = true;
      this.options.message = message;
      this.options.type = 'warning';
      this.options.icon = 'mdi-alert-outline';
      this.options.to = to;
    },
  },
});

const toast = {
  install(Vue) {
    Vue.$toast = toastService;
    Vue.prototype.$toast = toastService;
  },
};

export default toast;
