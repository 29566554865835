import audit from '../audit/store';
import terms from '../terms/store';

export default {
  namespaced: true,
  modules: {
    audit,
    terms,
  },
};
