import { zBffApi } from '@/api';
import { createCommandService } from '@/services';

const getApplicationsByClient = async ({ clientId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zBffApi}/aplicacao/cliente/${clientId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getUserAppsByClient = async ({ clientId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zBffApi}/usuario/aplicacao/cliente/${clientId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export { getApplicationsByClient, getUserAppsByClient };
