<template>
  <main-container show-actions>
    <template v-slot:actions>
      <v-layout justify-end class="mb-6">
        <v-text-field
          v-model="searchValue"
          clearable
          hide-details
          append-icon="mdi-magnify"
          class="input-search"
          placeholder="Pesquisar"
        />
      </v-layout>
    </template>
    <template v-slot:content>
      <custom-data-table-card
        disable-active-badge
        show-divider
        :items="aplicacoes"
        :loading="loading"
        :search="searchValue"
        @click="onClickCard"
      />
    </template>
  </main-container>
</template>

<script>
import MainContainer from '@/components/MainContainer.vue';
import CustomDataTableCard from '@/components/CustomDataTableCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ApplicationQuery',
  components: {
    MainContainer,
    CustomDataTableCard,
  },
  computed: {
    ...mapGetters('gestao/lgpd/terms', ['aplicacoes']),
    ...mapState('gestao/lgpd/terms', ['loading']),
  },
  data() {
    return {
      searchValue: null,
    };
  },
  async created() {
    await this.loadAplicacoes();
  },
  methods: {
    ...mapActions('gestao/lgpd/terms', ['loadAplicacoes']),
    onClickCard({ id }) {
      this.$router.push({
        name: 'edit-lgpd',
        params: { id },
      });
    },
  },
};
</script>
