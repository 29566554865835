import Vue from 'vue';
import router from '@/router';

import {
  getPermissions,
  getPermissionById,
  getPermissionTypes,
  createPermissionService,
  deletePermissionService,
  updatePermissionService,
} from '../service';

const loadPermissions = async ({ commit }, { application, query }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getPermissions({
    application,
    query,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'permissions',
        payload: data?.content,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        payload: data?.totalElements,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalPages',
        payload: data?.totalPages,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar permissões');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const loadPermissionById = async ({ commit }, { id, application }) => {
  await getPermissionById({
    id,
    application,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'permission',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar permissão');
    },
  });
};

const loadPermissionTypes = ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTypes',
    payload: true,
  });

  getPermissionTypes({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'types',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar tipos de permissão');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTypes',
        payload: false,
      });
    },
  });
};

const createPermission = ({ getters, commit }, application, saveAndNew) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadPermission } = getters;

  createPermissionService({
    payload: payloadPermission,
    application,
    onSuccess: () => {
      router.push(
        !saveAndNew
          ? { name: 'permissionQuery' }
          : { name: 'permissionEdit', params: { id: payloadPermission?.id } },
      );

      Vue.$toast.success('Permissão criada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao criar permissão');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const updatePermission = ({ getters, commit }, { application, saveAndNew }) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadPermission } = getters;

  updatePermissionService({
    payload: payloadPermission,
    application,
    onSuccess: () => {
      !saveAndNew && router.push({ name: 'permissionQuery' });

      Vue.$toast.success('Permissão alterada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao atualizar permissão');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const deletePermission = async ({ state, commit, dispatch }, { id, application, query }) => {
  await deletePermissionService({
    id,
    application,
    onSuccess: () => {
      Vue.$toast.success('Permissão excluída com sucesso.');

      if (state.permissions.length === 1) {
        router.replace({
          name: 'permissionQuery',
          query: {
            ...query,
            page: query.page,
          },
        });
      }

      dispatch('loadPermissions', query);

      commit('RESET_FORM_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao excluir permissão');
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadPermissions,
  loadPermissionById,
  loadPermissionTypes,
  createPermission,
  updatePermission,
  deletePermission,
  updateField,
  resetState,
};
