import ProfileIndex from '../Index.vue';
import ProfileQuery from '../Query.vue';

const fatherRouteName = 'perfil';

export default [
  {
    path: '/perfil',
    name: fatherRouteName,
    props: true,
    component: ProfileIndex,
    redirect: { name: fatherRouteName },
    children: [
      {
        path: '',
        name: fatherRouteName,
        props: true,
        meta: {
          title: 'Cadastros',
          subtitle: 'Perfil de acesso',
          applicationKey: 'workspace',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        component: ProfileQuery,
      },
      {
        path: 'create',
        name: 'create-perfil',
        props: true,
        meta: {
          title: 'Cadastros',
          subtitle: 'Novo perfil de acesso',
          backPage: 'perfil',
          applicationKey: 'workspace',
          action: 'create',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Crud.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-perfil',
        props: true,
        meta: {
          title: 'Cadastros',
          subtitle: 'Editar perfil de acesso',
          backPage: 'perfil',
          applicationKey: 'workspace',
          action: 'edit',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Crud.vue'),
      },
    ],
  },
];
