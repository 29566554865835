import Vue from 'vue';
import { getAuditActions } from '@/views/lgpd/service';
import { createQueryParams } from '@/utils/queryParam';
import { getApplicationsByClient } from '@/views/portal/service';
import { formatApps } from '@/utils/clientApplicationsFormat';

const formatAuditQueryObj = (filters) => {
  const { starHour, endHour, ...othersFilter } = filters;
  const hasStartHour = starHour != null && starHour.trim() !== '';
  const hasEndHour = endHour != null && endHour.trim() !== '';

  othersFilter.sort = 'record.date,desc';

  if (!hasStartHour && !hasEndHour) {
    return othersFilter;
  }

  const newFilters = structuredClone(othersFilter);

  if (hasStartHour) {
    newFilters.startDate = `${newFilters.startDate} ${starHour}`;
  }

  if (hasEndHour) {
    newFilters.endDate = `${newFilters.endDate} ${endHour}`;
  }

  newFilters.sort = 'record.date,desc';

  return newFilters;
};

const loadApplications = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoadingApps',
    payload: true,
  });

  await getApplicationsByClient({
    clientId: Vue.prototype.getLoggedUser()?.clientId,
    onSuccess: async ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'applications',
        payload: formatApps(data, true),
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'isLoadingApps',
        payload: false,
      }),
  });
};

const findAuditActions = async ({ commit, getters }) => {
  commit('GENERIC_MUTATION', {
    property: 'isSearching',
    payload: true,
  });

  const { filters, pageable } = getters;

  await getAuditActions({
    searchString: createQueryParams(formatAuditQueryObj(filters), pageable),
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'records',
        payload: data?.content ?? [],
      });
      commit('UPDATE_PAGEABLE_FIELD', {
        property: 'size',
        payload: data?.totalElements ?? pageable.size,
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar ações auditadas.');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isSearching',
        payload: false,
      });
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const updateFilterField = ({ commit }, { property, value }) => {
  commit('UPDATE_FILTER_FIELD', {
    property,
    payload: value,
  });

  commit('UPDATE_PAGEABLE_FIELD', {
    property: 'page',
    payload: 1,
  });
};

const updatePageableField = ({ commit }, { property, value }) => {
  commit('UPDATE_PAGEABLE_FIELD', {
    property,
    payload: value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadApplications,
  findAuditActions,
  updateField,
  updateFilterField,
  updatePageableField,
  resetState,
};
