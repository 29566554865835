import Vue from 'vue';
import router from '@/router';

import {
  createUserService,
  deleteUserService,
  getUserById,
  getUsers,
  loadBlockedUsersService,
  loadExternalUsersService,
  isExternalUserService,
  saveClientExternalUserService,
  updateBlockedUserService,
  updateExternalUserService,
  updateUserService,
  validEmailService,
} from '../service';

import { getProfiles, getProfilesUser, putProfilesUser } from '@/views/profile/service';
import { refreshTokenApi } from '@/views/myProfile/service';
import { getCookie, setCookie } from '@zitrus/utils-service';
import { getApplicationsByClient } from '@/views/portal/service';
import { formatApps } from '@/utils/clientApplicationsFormat';
import { reloadUserPermissions } from '@/services/zpermission';
import { createQueryParams } from '@/utils/queryParam';

const loadUsersData = async ({ commit, dispatch }, { type, clientId }) => {
  const types = {
    ALL: 'loadUsers',
    ENABLED: 'loadUsers',
    DISABLED: 'loadUsers',
    BLOCKED: 'loadBlockedUsers',
    EXTERNAL: 'loadExternalUsers',
  };

  dispatch('resetState');

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await dispatch(`${types[type]}`, {
    type,
    clientId,
  });

  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: false,
  });
};

const loadUsers = async ({ commit }, { type, clientId }) => {
  await getUsers({
    type,
    clientId,
    onSuccess: async ({ data }) => {
      let matchedUsers = [];

      if (data.usuarios) {
        matchedUsers = data?.usuarios.map((user) => {
          const matchedTenant = data['usuarios-tenant'].find(
            (tenant) => user.id === tenant.userId && tenant.external,
          );
          return {
            ...user,
            active: matchedTenant?.active ?? user.active,
            isExternal: matchedTenant?.external,
          };
        });
      }

      commit('GENERIC_MUTATION', {
        property: 'users',
        payload: matchedUsers,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar usuários';

      Vue.$toast.error(message);
    },
  });
};

const loadBlockedUsers = async ({ commit }, { clientId }) => {
  await loadBlockedUsersService({
    clientId,
    onSuccess: ({ data }) => {
      const blockedUsers = data?.usuarios?.map((user) => {
        return { ...user, isBlocked: true };
      });

      commit('GENERIC_MUTATION', {
        property: 'users',
        payload: blockedUsers,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar usuários bloqueados';

      Vue.$toast.error(message);
    },
  });
};

const loadExternalUsers = async ({ commit }, { clientId }) => {
  await loadExternalUsersService({
    clientId,
    onSuccess: ({ data }) => {
      const externalUsers = data?.usuarios?.map((user) => {
        return { ...user, isExternal: true };
      });

      commit('GENERIC_MUTATION', {
        property: 'users',
        payload: externalUsers,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar usuários externos';

      Vue.$toast.error(message);
    },
  });
};

const loadUserById = async ({ commit, dispatch, state }, { userId, clientId }) => {
  await getUserById({
    userId,
    onSuccess: async ({ data }) => {
      await dispatch('isExternalUser', {
        userId,
        clientId,
        responsibleClientId: data.responsibleClient,
      });

      commit('GENERIC_MUTATION', {
        property: 'emailNotValid',
        payload: false,
      });

      commit('GENERIC_MUTATION', {
        property: 'user',
        payload: {
          ...data,
          active: state.currentClientUserEnabled,
          swithStatus: data.status === 'HABILITADO',
        },
      });

      commit('GENERIC_MUTATION', {
        property: 'originalUser',
        payload: {
          ...data,
          active: state.currentClientUserEnabled,
          swithStatus: data.status === 'HABILITADO',
        },
      });

      dispatch('loadUserAccessPermissions', userId);
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar usuário';

      Vue.$toast.error(message);
    },
  });
};

const loadApplications = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingApplicationsTable',
    payload: true,
  });

  await getApplicationsByClient({
    clientId: Vue.prototype.getLoggedUser()?.clientId,
    onSuccess: ({ data }) =>
      commit('GENERIC_MUTATION', {
        property: 'applications',
        payload: formatApps(data),
      }),
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar aplicações';

      Vue.$toast.error(message);
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingApplicationsTable',
        payload: false,
      }),
  });
};

const loadProfiles = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingProfilesTable',
    payload: true,
  });

  await getProfiles({
    payload: createQueryParams({ enabled: true }, null, { useQuestionMark: false }),
    onSuccess: async ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'profiles',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar perfis do usuário';

      Vue.$toast.error(message);
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingProfilesTable',
        payload: false,
      });
    },
  });
};

const loadUserAccessPermissions = async ({ commit }, userId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingAccessPermissisons',
    payload: true,
  });

  await getProfilesUser({
    userId,
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'accessPermissions',
        value: [...data],
      });
    },
    onCustomError: ({ response }) => {
      if (response.status === 404) return;

      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar perfis do usuário';

      Vue.$toast.error(message);
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingAccessPermissisons',
        payload: false,
      });
    },
  });
};

const createUser = async ({ getters, commit, dispatch }) => {
  commit('GENERIC_MUTATION', {
    property: 'isSavingAndNew',
    payload: true,
  });

  const { payloadUser } = getters;

  await createUserService({
    payload: payloadUser,
    onSuccess: async ({ data }) => {
      await dispatch('setPermissionUser', data);
    },
    onCustomError: ({ response }) => {
      if (response?.status === 409) {
        commit('GENERIC_MUTATION', {
          property: 'fieldErrors',
          payload: { ...getters.fieldErrors, email: ['E-mail já cadastrado.'] },
        });
      }
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao criar usuário';

      Vue.$toast.error(message);
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isSavingAndNew',
        payload: false,
      });
    },
  });
};

const updateUser = async ({ getters, commit, dispatch }, payload) => {
  commit('GENERIC_MUTATION', {
    property: 'isSaving',
    payload: true,
  });

  const { loggedUser, applicationKey } = payload;
  const { payloadEditUser, payloadAccessPermissions } = getters;

  if (payloadEditUser.isBlocked) {
    await updateBlockedUserService({
      userId: payloadEditUser?.id,
      onCustomError: ({ response }) => {
        const message =
          response?.data?.detail ||
          response?.data?.message ||
          response?.data?.title ||
          'Ocorreu um erro de requisição ao atualizar o usuário bloqueado';

        commit('GENERIC_MUTATION', {
          property: 'isSaving',
          payload: false,
        });

        Vue.$toast.error(message);
        throw new Error(message);
      },
    });
  }

  if (payloadEditUser.isExternal) {
    await updateExternalUserService({
      clientId: loggedUser?.clientId,
      userId: payloadEditUser?.id,
      payload: {
        userEnabled: payloadEditUser.active,
      },
      onCustomError: ({ response }) => {
        const message =
          response?.data?.detail ||
          response?.data?.message ||
          response?.data?.title ||
          'Ocorreu um erro de requisição ao atualizar o usuário externo';

        commit('GENERIC_MUTATION', {
          property: 'isSaving',
          payload: false,
        });

        Vue.$toast.error(message);
        throw new Error(message);
      },
    });
  } else {
    await updateUserService({
      payload: payloadEditUser,
      onCustomError: ({ response }) => {
        const message =
          response?.data?.detail ||
          response?.data?.message ||
          response?.data?.title ||
          'Ocorreu um erro de requisição ao atualizar o usuário';

        commit('GENERIC_MUTATION', {
          property: 'isSaving',
          payload: false,
        });

        Vue.$toast.error(message);
        throw new Error(message);
      },
    });
  }

  if (loggedUser?.userId === payloadEditUser.id) {
    await dispatch('refreshUserToken');
  }

  if (payloadAccessPermissions?.length > 0) {
    await dispatch('updateUserProfiles', { loggedUser, applicationKey });
  }

  commit('RESET_STATE');
  commit('GENERIC_MUTATION', {
    property: 'isSaving',
    payload: false,
  });

  Vue.$toast.success('Usuário alterado com sucesso.');
  router.push({ name: 'usuario' });
};

const refreshUserToken = async () => {
  await refreshTokenApi({
    payload: { refreshToken: await getCookie('refreshToken') },
    onSuccess: ({ data }) => {
      setCookie('token', data?.accessToken);
      setCookie('refreshToken', data?.refreshToken);
    },
  });
};

const updateUserProfiles = async ({ getters, commit }, { loggedUser, applicationKey }) => {
  const { payloadEditUser, payloadAccessPermissions } = getters;

  await putProfilesUser({
    userId: payloadEditUser.id,
    payload: payloadAccessPermissions,
    onSuccess: async () => {
      if (loggedUser?.userId === payloadEditUser.id) {
        await reloadUserPermissions({ applicationKey });
      }
    },
    onCustomError: ({ response }) => {
      const message =
        (response?.data?.detail && !Array.isArray(response?.data?.detail)) ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao salvar aplicações/perfis do usuário';

      commit('GENERIC_MUTATION', {
        property: 'isSaving',
        payload: false,
      });

      Vue.$toast.error(message);
      throw new Error(message);
    },
  });
};

const deleteUser = async ({ state, commit, dispatch }, { id, query }) => {
  await deleteUserService({
    id,
    onSuccess: () => {
      Vue.$toast.success('Usuário excluído com sucesso.');

      if (state.users.length === 1) {
        router.replace({
          name: 'usuario',
          query: {
            ...query,
            page: query.page,
          },
        });
      }

      dispatch('loadUsers', query);

      commit('RESET_FORM_STATE');
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao excluir usuário';

      Vue.$toast.error(message);
    },
  });
};

const updateField = ({ commit, state }, { property, value }) => {
  const { originalUser } = state;

  commit('UPDATE_FIELD', {
    property,
    value,
  });

  commit('GENERIC_MUTATION', {
    property: 'isFormDataDirty',
    payload: originalUser[property] !== value,
  });
};

const includeAccessPermission = ({ commit }) => {
  commit('INCLUDE_ACCESS_PERMISSION');
};

const updateAccessPermissionField = ({ commit }, { index, value }) => {
  commit('UPDATE_ACCESS_PERMISSION_FIELD', {
    index,
    value,
  });
  commit('GENERIC_MUTATION', {
    property: 'isFormDataDirty',
    payload: true,
  });
};

const updateFieldErrors = ({ commit, getters }, { property, value }) => {
  commit('GENERIC_MUTATION', {
    property: 'fieldErrors',
    payload: { ...getters.fieldErrors, [property]: value },
  });
};

const removeAccessPermission = ({ state, commit }, index) => {
  if (state.user.accessPermissions?.length === 1) {
    commit('UPDATE_ACCESS_PERMISSION_FIELD', {
      index: 0,
      value: {},
    });
  } else {
    commit('REMOVE_ACCESS_PERMISSION', {
      index,
    });
  }
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

const validEmail = async ({ commit }, payload) => {
  await validEmailService({
    payload,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'emailNotValid',
        payload: false,
      });

      const isExternalUser = (data?.inUse && !data?.isOnYourClient) || false;

      commit('GENERIC_MUTATION', {
        property: 'showExternalUserDialog',
        payload: isExternalUser,
      });

      commit('GENERIC_MUTATION', {
        property: 'isExternalUser',
        payload: isExternalUser,
      });

      if (data?.user !== null) {
        commit('GENERIC_MUTATION', {
          property: 'user',
          payload: {
            ...data?.user,
            email: payload,
            accessPermissions: [{}],
          },
        });
      }
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'fieldErrors',
        payload: response.data?.detail,
      });

      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao validar e-mail';

      Vue.$toast.error(message);
    },
  });
};

const updateFieldGlobal = ({ commit }, { property, payload }) => {
  commit('GENERIC_MUTATION', {
    property,
    payload,
  });
};

const saveClientExternalUser = async ({ dispatch }, { clientId, userId, user }) => {
  await saveClientExternalUserService({
    payload: { clientId, userId, user, external: true },
    onSuccess: async () => {
      await dispatch('setPermissionUser', user);
      Vue.$toast.success('Usuário externo salvo com sucesso.');
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao salvar usuário externo';

      Vue.$toast.error(message);
    },
  });
};

const isExternalUser = async ({ commit }, { userId, clientId, responsibleClientId }) => {
  await isExternalUserService({
    userId,
    clientId,
    responsibleClientId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'externalUser',
        payload: data.responsibleClient,
      });

      commit('GENERIC_MUTATION', {
        property: 'isExternalUser',
        payload: !!data.currentClient.isExternal,
      });

      commit('GENERIC_MUTATION', {
        property: 'currentClientUserEnabled',
        payload: data.currentClient.userEnabled,
      });
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.detail ||
        response?.data?.message ||
        response?.data?.title ||
        'Ocorreu um erro de requisição ao buscar usuário externo';

      Vue.$toast.error(message);
    },
  });
};

const setPermissionUser = async ({ getters, commit }, payload) => {
  const { payloadAccessPermissions } = getters;

  if (payloadAccessPermissions?.length > 0) {
    await putProfilesUser({
      userId: payload.id,
      payload: payloadAccessPermissions,
      onSuccess: () => {
        commit('RESET_STATE');

        Vue.$toast.success('Usuário criado com sucesso.');

        router.push({ name: 'usuario' });
      },
      onCustomError: ({ response }) => {
        const message =
          response?.data?.detail ||
          response?.data?.message ||
          response?.data?.title ||
          'Ocorreu um erro de requisição ao salvar aplicações/perfis do usuário';

        Vue.$toast.error(message);

        commit('GENERIC_MUTATION', {
          property: 'showExternalUserDialog',
          payload: true,
        });
      },
      onFinally: () => {
        commit('GENERIC_MUTATION', {
          property: 'isSavingAndNew',
          payload: true,
        });
      },
    });
  } else {
    commit('RESET_STATE');

    Vue.$toast.success('Usuário criado com sucesso.');
    router.push({ name: 'usuario' });
  }
};

export default {
  loadUsersData,
  loadUsers,
  loadBlockedUsers,
  loadExternalUsers,
  loadUserById,
  loadUserAccessPermissions,
  loadApplications,
  loadProfiles,
  createUser,
  updateUser,
  deleteUser,
  refreshUserToken,
  updateUserProfiles,
  updateField,
  includeAccessPermission,
  updateAccessPermissionField,
  updateFieldErrors,
  removeAccessPermission,
  resetState,
  validEmail,
  updateFieldGlobal,
  saveClientExternalUser,
  isExternalUser,
  setPermissionUser,
};
