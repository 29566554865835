<template>
  <div class="container-portal">
    <section class="container-portal__left">
      <z-logo contain height="140px" width="311px" />
    </section>
    <section class="container-portal__right">
      <div class="content-portal">
        <div class="content-portal__form">
          <v-overlay
            v-if="
              portal.isLoadingClients ||
              portal.isLoadingApplications ||
              portal.isPutingClientOnToken ||
              portal.isOpeningApplication
            "
            absolute
            color="bg-neutral-gray-low"
            opacity="0.6"
          >
            <loader
              name="spinning"
              size="12"
              speed="1.5"
              opacity="60"
              disableScrolling="true"
              object="#75D991"
              objectbg="#42395E"
              bg="white"
            />
          </v-overlay>
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ZLogo from '@/components/z-logo.vue';

export default {
  name: 'PortalView',
  components: {
    ZLogo,
  },
  computed: {
    ...mapState('gestao/portal', ['portal']),
  },
  created() {
    this.resetState();
  },
  mounted() {
    const rootElement = document.querySelector('#z-gestao .v-application--wrap');
    if (rootElement) {
      rootElement.style.setProperty('padding', '0px', 'important');
    }
  },
  beforeDestroy() {
    const rootElement = document.querySelector('#z-gestao .v-application--wrap');
    if (rootElement) {
      rootElement.removeAttribute('style');
    }
  },
  methods: {
    ...mapActions('gestao/portal', ['resetState']),
  },
};
</script>

<style lang="scss" scoped>
.container-portal {
  height: 100vh;

  background: linear-gradient(90deg, #21d7ac 80%, #1f747a);
  background-size: 110%;

  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  display: grid;
  grid-template: 'left right';
  grid-template-columns: 1fr 1fr;

  &__left {
    grid-area: left;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    grid-area: right;

    display: flex;
    justify-content: center;

    .content-portal {
      overflow: auto;
      width: 90%;
      position: relative;
      height: calc(100vh - 82px);
      padding: 0 73px 73px 73px;

      border-radius: 0 0 12px 12px;
      background-color: theme('colors.neutral-gray-low');

      &__form {
        width: 100%;
        margin-top: 93px;

        display: flex;
        justify-content: center;

        :deep(#overlay-spinner) {
          position: relative;
          z-index: 3;
        }

        :deep(.loader-spinner) {
          position: relative;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .container-portal {
    &__right {
      .content-portal {
        margin: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .container-portal {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template:
      'right'
      'left';
    gap: 40px;

    &__right {
      .content-portal {
        margin: 0;
      }
    }

    &__left {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-height: 768px) {
  .container-portal {
    &__right {
      .content-portal {
        height: calc(100vh - 50px);
        padding: 0 50px 50px 50px;

        &__form {
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
