const toKebab = (string) => string.replace(/[_\s]+/g, '-');

const toCamel = (string) =>
  toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word;
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');

const toTitle = (string) =>
  toKebab(string)
    .split('-')
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(' ');

const toSentence = (string) => {
  const interim = toKebab(string).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
};

const toNormalLettersWithoutSpecialChar = (string) => {
  const firstSpace = string.indexOf(' ');
  const newString = firstSpace > 0 ? string.slice(0, firstSpace) : string;
  const newStringWithoutSpecialCharacters = newString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return newStringWithoutSpecialCharacters.toLowerCase();
};

export { toKebab, toCamel, toTitle, toSentence, toNormalLettersWithoutSpecialChar };
