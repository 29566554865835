import Vue from 'vue';
import initialState from './state';

export default {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  RESET_FORM_STATE(state) {
    Object.assign(state.user, initialState);
  },
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD(state, { property, value }) {
    Vue.set(state.user, property, value);
  },
  INCLUDE_ACCESS_PERMISSION(state) {
    Vue.set(state.user, 'accessPermissions', [{}, ...state.user.accessPermissions]);
  },
  UPDATE_ACCESS_PERMISSION_FIELD(state, { index, value }) {
    Vue.set(state.user.accessPermissions, index, value);
  },
  REMOVE_ACCESS_PERMISSION(state, { index }) {
    Vue.delete(state.user.accessPermissions, index);
  },
};
