import { initialStatePayload } from '../constants';

export default () => ({
  loadingTable: false,
  loadingForm: false,
  integrations: {},
  applications: [],
  integrationTypes: [],
  authenticationTypes: [],
  payload: { ...initialStatePayload },
  initialState: { ...initialStatePayload },
});
