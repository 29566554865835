const forms = {
  applications: [],
  actions: ['INSERT', 'UPDATE', 'DELETE'],
  records: [],
  grid: {
    header: [
      { text: 'PERÍODO', value: 'date', width: 160 },
      { text: 'ID', value: 'objectId', width: 119 },
      { text: 'AÇÃO', value: 'action', width: 144 },
      { text: 'USUÁRIO', value: 'author', width: 290 },
      { text: 'VALOR ANTIGO', value: 'previousValue' },
      { text: 'VALOR ATUAL', value: 'currentValue' },
    ],
    pageable: {
      page: 1,
      size: 0,
      itemsPerPage: 10,
      itemsPerPageChoice: [5, 10, 15],
    },
  },
  filter: {
    objectId: null,
    application: '',
    entityName: '',
    startDate: '',
    starHour: '',
    endDate: '',
    endHour: '',
    author: '',
    origin: '',
    transactionId: null,
    event: '',
    userId: null,
    previousValue: '',
    currentValue: '',
  },
};

export default () => ({
  isSearching: false,
  isLoadingApps: false,
  isLoadingEntities: false,
  audit: { ...forms },
  initialState: { ...forms },
});
