<template>
  <v-row>
    <v-col>
      <v-data-iterator
        v-bind="{ items, loading, search, options, customGroup, customFilter }"
        disable-pagination
        hide-default-footer
        loading-text="Carregando dados para montagem da grid..."
      >
        <template v-slot:no-data>
          <div class="no-data">Nenhum registro disponível</div>
        </template>
        <template v-slot:no-results>
          <div class="no-data">Nenhum registro disponível</div>
        </template>
        <template v-slot:loading>
          <v-layout column align-center>
            <v-progress-linear indeterminate color="bg-primary-blue-base" />
          </v-layout>
        </template>
        <template v-slot:default="{ items, groupedItems }">
          <v-layout v-if="loading" column align-center>
            <v-progress-linear indeterminate color="bg-primary-blue-base" />
          </v-layout>
          <v-layout
            v-else-if="groupedItems && groupedItems.length > 0"
            column
            class="pt-4 px-2 pb-2"
          >
            <v-expansion-panels
              accordion
              flat
              multiple
              class="panel-grouped"
              :value="expandedPanel"
              @change="updateExpandedPanels"
            >
              <v-expansion-panel v-for="(itemGroup, indexGroup) of groupedItems" :key="indexGroup">
                <v-expansion-panel-header>{{ itemGroup.name }}</v-expansion-panel-header>
                <v-expansion-panel-content class="pt-3">
                  <v-row>
                    <v-col
                      v-for="(itemSub, indexSub) of itemGroup.items"
                      :key="indexSub"
                      :lg="gridSizes.lg"
                      :md="gridSizes.md"
                      :cols="gridSizes.cols"
                    >
                      <data-table-card :item="itemSub" @click="$emit('click', itemSub)" />
                      <hr v-if="showDivider" class="card-grid-divider" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>

          <v-layout v-else column class="pt-6 px-2 pb-2">
            <v-row>
              <v-col
                v-for="(item, index) of items"
                :key="index"
                :lg="gridSizes.lg"
                :md="gridSizes.md"
                :cols="gridSizes.cols"
              >
                <data-table-card :item="item" @click="$emit('click', item)" />
                <hr v-if="showDivider" class="card-grid-divider" />
              </v-col>
            </v-row>
          </v-layout>
        </template>
      </v-data-iterator>
    </v-col>
  </v-row>
</template>

<script>
import DataTableCard from './DataTableCard.vue';

export default {
  name: 'CustomDataTableCard',
  components: {
    DataTableCard,
  },
  props: {
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    search: { type: String, default: null },
    options: { type: Object, default: () => {} },
    showDivider: { type: Boolean, default: false },
  },
  computed: {
    showDividerClients() {
      return (listItems, index) => {
        const { xl, lg, md } = this.$vuetify.breakpoint;

        if (!xl && !lg && !md) {
          return index < listItems.length - 1;
        }

        const itensPerLine =
          12 / (this.gridSizes[this.$vuetify.breakpoint.name] ?? this.gridSizes.cols);

        const totalDividers = Math.ceil(listItems.length / itensPerLine);

        if (Number(totalDividers) === 1) {
          return false;
        }

        const lastIndexToShowDivider = (totalDividers - 1) * itensPerLine;
        return index < lastIndexToShowDivider;
      };
    },
  },
  data() {
    return {
      expandedPanel: [],
      isFirstLoad: true,
      gridSizes: {
        xl: 3,
        lg: 3,
        md: 6,
        cols: 12,
      },
    };
  },
  methods: {
    updateExpandedPanels(value) {
      this.expandedPanel = Object.assign([], value);
    },
    customFilter(items, search) {
      if (search && search.length > 0) {
        this.updateExpandedPanels(Array.from(Array(items.length).keys()));

        return items.filter((item) => {
          if (item?.items) {
            item.items.filter((groupedItem) => {
              return (
                groupedItem.title?.toLowerCase()?.startsWith(search?.toLocaleLowerCase()) ||
                groupedItem.subtitle?.toLowerCase()?.startsWith(search?.toLocaleLowerCase())
              );
            });
          }

          return (
            item.title?.toLowerCase()?.startsWith(search?.toLocaleLowerCase()) ||
            item.subtitle?.toLowerCase()?.startsWith(search?.toLocaleLowerCase())
          );
        });
      }

      return items;
    },
    customGroup(items, groupBy) {
      if (!groupBy || !items || items?.length === 0) {
        return items;
      }

      const splitGroupBy = groupBy.split('.');
      let groupedList = [];

      for (const item of items) {
        const valueGroup = this.findValueGroupBy(item, splitGroupBy);

        if (valueGroup) {
          const groupIndex = groupedList.findIndex((item) => item?.name === valueGroup);
          if (groupIndex < 0) {
            if (this.isFirstLoad) {
              this.expandedPanel = [...new Set([...this.expandedPanel, ...[groupedList.length]])];
            }
            groupedList.push({ name: valueGroup, items: [item] });
          } else {
            groupedList[groupIndex].items.push(item);
          }
        }
      }

      this.isFirstLoad = false;
      return groupedList;
    },
    findValueGroupBy(item, keys) {
      let resultValue = '';

      if (!keys) {
        return null;
      } else if (keys?.length === 1) {
        resultValue = item[keys[0]];
      } else {
        resultValue = this.findValueGroupBy(item[keys[0]], keys.slice(1));
      }

      return resultValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-grid-divider {
  width: 98%;
  border: 0px;
  border-bottom: 1px solid theme('colors.neutral-gray-disabled');
  height: 5px;
}

.row-subitem {
  width: 100%;
}

.panel-grouped {
  :deep(.v-expansion-panel:not(:last-of-type)) {
    margin-bottom: 8px !important;
  }

  :deep(.v-expansion-panel-header) {
    padding: 0px 0px 0px 2px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    min-height: 40px !important;
    color: theme('colors.primary-blue-base') !important;
    border-bottom: 1px solid theme('colors.primary-blue-base');

    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;

    &:hover {
      background-color: theme('colors.neutral-gray-low');
    }
  }
}

.no-data {
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 24px;
  padding: 10px;
  color: theme('colors.primary-blue-base');
  background-color: theme('colors.neutral-gray-low');
}
</style>
