import logos from '@/utils/logos';

const formatApps = (data, removeWorkspace = false) => {
  const { aplicacoes, aplicacaoWorkspace } = data;
  const apps = aplicacoes?.map((app) => ({ ...app, logo: logos[app.key] || '' })) || [];

  if (!removeWorkspace && aplicacaoWorkspace && Object.keys(aplicacaoWorkspace)?.length > 0) {
    apps.unshift({ ...aplicacaoWorkspace, logo: logos[aplicacaoWorkspace.key] || '' });
  }

  return apps;
};

const formatUserApps = (data, removeWorkspace = false) => {
  const aplicacoes = data['aplicacoes-usuario'] || [];
  const aplicacaoWorkspace = data['aplicacao-workspace'] || {};

  return formatApps({ aplicacoes, aplicacaoWorkspace }, removeWorkspace);
};

export { formatApps, formatUserApps };
