<template>
  <v-app id="z-gestao">
    <router-view />
    <z-toast />
  </v-app>
</template>

<script>
import { listenEvent } from '@/events/custom-events-service';
import ZToast from '@/components/z-toast.vue';

export default {
  name: 'App',
  components: { ZToast },
  created() {
    listenEvent('@zitrus/z-header/action-click-help', this.setHelperRoute);
  },
  beforeDestroy() {
    removeEventListener('@zitrus/z-header/action-click-help', this.setHelperRoute, false);
  },
  methods: {
    setHelperRoute() {
      this.$router.push({ name: 'not-found' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import './styles/global.scss';

#z-gestao {
  .btn__no-width {
    height: 0 !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 0 !important;
    color: theme('colors.primary-blue-base') !important;

    &:hover {
      color: theme('colors.primary-blue-medium') !important;
    }
  }

  .input-search {
    max-width: 265px;
  }
}
</style>
