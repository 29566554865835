<template>
  <v-progress-linear indeterminate v-if="isLoading" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'loadingProgress',
  computed: {
    ...mapGetters('gestao/loadingProgress', ['isLoading']),
  },
};
</script>
