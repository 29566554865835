import Vue from 'vue';

const findApplicationName = (integrations, applicationKey) =>
  integrations['z-permission']?.find((application) => application?.key === applicationKey)?.title ??
  '';

const itemsTableCard = ({ integrations }) =>
  integrations['z-workspace']?.map((integration) => ({
    id: integration?.id,
    title: integration?.integrationType,
    applicationName: findApplicationName(integrations, integration?.applicationKey),
    picture: integration?.picture,
    hideAvatarStatus: integration?.picture == null,
  }));

const payloadCrud = ({ payload }) => ({
  companyId: Vue.prototype.getLoggedUser()?.clientId,
  integrationType: payload?.integrationType,
  endpoint: payload?.endpoint,
  applicationKey: payload?.applicationKey,
  integrationCredential: {
    username: payload?.integrationCredential?.username,
    password: payload?.integrationCredential?.password,
    credentialScope: payload?.integrationCredential?.credentialScope,
    accessTokenUrl: payload?.integrationCredential?.accessTokenUrl,
    grantType: payload?.integrationCredential?.grantType,
    clientId: payload?.integrationCredential?.clientId,
    clientSecret: payload?.integrationCredential?.clientSecret,
    token: payload?.integrationCredential?.token,
    authType: payload?.integrationCredential?.authType,
  },
});

const isInitialState = ({ payload, initialState }) =>
  JSON.stringify(payload) === JSON.stringify(initialState);

export default {
  findApplicationName,
  itemsTableCard,
  payloadCrud,
  isInitialState,
};
