const payload = ({ profile }) => profile;

const attributeValue =
  ({ profile }) =>
  (attribute) => {
    return profile[attribute]?.value ?? false;
  };

const fieldErrors = ({ fieldErrors }) => fieldErrors;

export default { payload, attributeValue, fieldErrors };
