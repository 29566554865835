import { zPermissionApi } from '@/api';
import { createCommandService } from '@/services';

const getPermissionTypes = async ({ onSuccess, onCustomError, onFinally }) => {
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/permissao/tipos`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const getPermissions = async ({ application, query, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: query
      ? `${zPermissionApi}/aplicacao/${application}/permissao/paged?page=${query.page - 1}&size=${
          query.size
        }`
      : `${zPermissionApi}/aplicacao/${application}/permissao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getPermissionById = async ({ id, application, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${application}/permissao/id/${id}`,
    onSuccess,
    onCustomError,
  });

const createPermissionService = ({ payload, application, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${zPermissionApi}/aplicacao/${application}/permissao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deletePermissionService = ({ id, application, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${zPermissionApi}/aplicacao/${application}/permissao/${id}`,
    onSuccess,
    onCustomError,
  });

const updatePermissionService = ({ payload, application, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${zPermissionApi}/aplicacao/${application}/permissao/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getPermissions,
  getPermissionById,
  getPermissionTypes,
  createPermissionService,
  deletePermissionService,
  updatePermissionService,
};
