import UserIndex from '../Index.vue';
import UserQuery from '../Query.vue';

const fatherRouteName = 'usuario';

export default [
  {
    path: '/usuario',
    name: fatherRouteName,
    redirect: { name: fatherRouteName },
    component: UserIndex,
    children: [
      {
        path: '',
        name: 'usuario',
        meta: {
          title: 'Cadastros',
          subtitle: 'Usuário',
          applicationKey: 'workspace',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        component: UserQuery,
      },
      {
        path: 'create',
        name: 'create-usuario',
        meta: {
          title: 'Incluir novo usuário',
          backPage: 'usuario',
          applicationKey: 'workspace',
          action: 'create',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Crud.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-usuario',
        props: true,
        meta: {
          title: 'Cadastro de usuário',
          backPage: 'usuario',
          applicationKey: 'workspace',
          action: 'edit',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "edit" */ '../Crud.vue'),
      },
    ],
  },
];
