<template>
  <v-layout class="pa-3 align-center card-grid" @click="$emit('click', item)">
    <div class="pr-3">
      <div v-if="item?.logo">
        <v-img contain height="70px" width="90px" :src="item.logo" />
      </div>
      <v-avatar v-else-if="item.hideAvatarStatus" size="48" class="avatar-grid-item">
        <v-img
          v-if="item?.picture?.length > 0"
          alt="Imagem do card"
          class="mx-auto my-auto"
          :src="`data:image/png;base64,${item?.picture}`"
        />
        <span v-else>{{ getUserNameInitials(item.title) }}</span>
      </v-avatar>

      <v-badge
        v-else
        bottom
        overlap
        offset-x="18"
        offset-y="15"
        :icon="badgeIcon"
        :class="stylesBadge"
      >
        <v-avatar size="48" :class="stylesAvatar">
          <v-img
            v-if="item?.picture?.length > 0"
            alt="Imagem do card"
            class="mx-auto my-auto"
            :src="`data:image/png;base64,${item?.picture}`"
          />
          <span v-else>{{ getUserNameInitials(item.title) }}</span>
        </v-avatar>
      </v-badge>
    </div>

    <div class="text-start card-grid-text">
      <h1 class="font-semibold text-lg" :title="item.title">
        {{ item.title }}
      </h1>
      <h3 v-if="item?.subtitle" class="text-sm" :title="item.subtitle">
        {{ `${item?.subtitle}` }}
      </h3>
    </div>
  </v-layout>
</template>

<script>
import { getInitials } from '@/utils';

export default {
  name: 'DataTableCard',
  props: {
    item: { type: Object, default: Object },
  },
  computed: {
    getUserNameInitials() {
      return (value) => getInitials(value);
    },
    isBlocked() {
      return this.item.status === 'BLOQUEADO';
    },
    badgeIcon() {
      return this.isBlocked ? 'mdi-block-helper' : '';
    },
    stylesAvatar() {
      return [
        'avatar-grid-item',
        {
          'avatar-grid-item--active': this.item.active,
          'avatar-grid-item--inactive': !this.item.active,
          'avatar-grid-item__external--active': this.item.isExternal,
          'avatar-grid-item__external--pending': this.item.externalPending,
          'avatar-grid-item--blocked': this.isBlocked,
        },
      ];
    },
    stylesBadge() {
      return [
        'badge',
        {
          'badge--active': this.item.active,
          'badge--inactive': !this.item.active,
          'badge__external--active': this.item.isExternal,
          'badge__external--pending': this.item.externalPending,
          'badge--blocked': this.isBlocked,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.card-grid {
  border-radius: 17px;

  &:hover {
    cursor: pointer;
    background-color: theme('colors.primary-blue-disabled');
  }

  :deep(.v-badge) {
    &.badge--active .v-badge__badge {
      background-color: theme('colors.feedback-green-high') !important;
    }
    &.badge__external--active .v-badge__badge {
      background-color: theme('colors.feedback-blue-high') !important;
    }
    &.badge__external--pending .v-badge__badge {
      background-color: theme('colors.feedback-blue-low') !important;
    }

    &.badge--inactive .v-badge__badge {
      background-color: theme('colors.neutral-gray-disabled') !important;
    }

    &.badge--blocked .v-badge__badge {
      background-color: theme('colors.white') !important;
    }
  }

  :deep(.v-badge__badge) {
    height: 16px !important;
    min-width: 16px !important;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .v-icon {
      font-size: 12px;
      color: theme('colors.feedback-red-high');
    }

    border-width: 2px !important;
    border-style: solid !important;
    border-color: theme('colors.white') !important;
  }

  .avatar-grid-item {
    border-width: 2px !important;
    border-style: solid !important;

    color: theme('colors.white') !important;
    background-color: theme('colors.primary-blue-base');
    border-color: theme('colors.primary-blue-base') !important;

    &--active {
      background-color: theme('colors.feedback-green-high');
      border-color: theme('colors.feedback-green-high') !important;
    }

    &--inactive {
      background-color: theme('colors.neutral-gray-disabled');
      border-color: theme('colors.neutral-gray-disabled') !important;
    }

    &__external {
      &--active {
        background-color: theme('colors.feedback-blue-high');
        border-color: theme('colors.feedback-blue-high') !important;
      }

      &--pending {
        background-color: theme('colors.feedback-blue-low');
        border-color: theme('colors.feedback-blue-low') !important;
      }
    }

    &--blocked {
      background-color: theme('colors.feedback-red-high');
      border-color: theme('colors.feedback-red-high') !important;
    }

    span {
      color: theme('colors.white') !important;
      font-size: 16px !important;
    }
  }

  .card-grid-text {
    width: calc(100% - 60px);

    h1,
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }
}
</style>
