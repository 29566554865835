import loadingProgress from '@/views/loading-progress/store';
import user from '@/views/user/store';
import profile from '@/views/profile/store';
import permission from '@/views/application/permission/store';
import application from '@/views/application/store';
import company from '@/views/company/store';
import portal from '@/views/portal/store';
import userProfile from '@/views/myProfile/store';
import lgpd from '@/views/lgpd/store';
import integration from '@/views/integration/store';
import privacyNotice from '@/views/privacy-notice/store';

export default {
  namespaced: true,
  modules: {
    loadingProgress,
    user,
    profile,
    permission,
    application,
    company,
    portal,
    userProfile,
    lgpd,
    integration,
    privacyNotice,
  },
};
