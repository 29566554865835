import { zBffApi, zWorkspaceApi } from '@/api';
import { createCommandService } from '@/services';

const getIntegrations = async ({ onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zBffApi}/dados-integracoes`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const getIntegrationTypes = async ({ onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/integracao/tipo`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const getAuthenticationTypes = async ({ onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/integracao/autenticacao/tipo`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const getIntegrationById = async ({ integrationId, onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/integracao/${integrationId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const createIntegrationService = async ({ payload, onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'post',
    payload,
    uri: `${zWorkspaceApi}/integracao`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const updateIntegrationService = async ({
  payload,
  integrationId,
  onSuccess,
  onCustomError,
  onFinally,
}) => {
  await createCommandService({
    method: 'put',
    payload,
    uri: `${zWorkspaceApi}/integracao/${integrationId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

export {
  getIntegrations,
  getIntegrationTypes,
  getAuthenticationTypes,
  getIntegrationById,
  createIntegrationService,
  updateIntegrationService,
};
