import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
import '@mdi/font/css/materialdesignicons.css';
import tailwind from '../../tailwind.config';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        colors: {
          ...tailwind.presets[0].default.theme.extend.colors,
        },
      },
    },
  },
});
