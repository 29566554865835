import moment from 'moment';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_BRAZILIAN_FORMAT,
  DEFAULT_BRAZILIAN_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from './dateFormats';

const formatDate = (
  date,
  destinationFormat = DEFAULT_BRAZILIAN_FORMAT,
  originFormat = DEFAULT_DATE_FORMAT,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateTime = (
  date,
  destinationFormat = DEFAULT_BRAZILIAN_DATE_TIME_FORMAT,
  originFormat = DEFAULT_DATE_TIME_FORMAT,
) =>
  moment(date, originFormat).isValid() ? moment(date, originFormat).format(destinationFormat) : '';

const formatDateRestore = (date) => formatDate(date, DEFAULT_DATE_FORMAT, DEFAULT_BRAZILIAN_FORMAT);

export { formatDate, formatDateTime, formatDateRestore };
