const ressus = require('@/assets/logo_ressus.svg');
const zitrus = require('@/assets/zitrus_logo.svg');
const gpl = require('@/assets/logo_gpl.svg');
const workspace = require('@/assets/logo_workspace.svg');
const lock = require('@/assets/logo_lock_access.svg');

const logos = {
  ressus,
  zitrus,
  gpl,
  workspace,
  lock,
};

export default logos;
