const forms = {
  id: null,
  name: '',
  document: '',
  address: {
    postalCode: '',
    complement: '',
    street: '',
    district: '',
    number: null,
    city: '',
    state: '',
  },
  active: true,
  hasTenant: true,
};

export default () => ({
  company: { ...forms },
  findingCep: false,
  loadingCities: false,
  loadingStates: false,
  cities: [],
  states: [],

  companies: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,

  isSaving: false,
  isSavingAndNew: false,

  initialState: {
    id: null,
    name: '',
    document: '',
    address: {
      postalCode: '',
      complement: '',
      street: '',
      district: '',
      number: null,
      city: '',
      state: '',
    },
    active: true,
    hasTenant: true,
  },
});
