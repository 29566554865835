const fatherRouteName = 'integracao';

export default [
  {
    path: '/integracao',
    name: fatherRouteName,
    props: true,
    component: () => import(/* webpackChunkName: "crud" */ '../Index.vue'),
    redirect: { name: fatherRouteName },
    children: [
      {
        path: '',
        name: fatherRouteName,
        props: true,
        meta: {
          title: 'Cadastros',
          subtitle: 'Integrações',
          applicationKey: 'workspace',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Query.vue'),
      },
      {
        path: 'create',
        name: 'create-integracao',
        props: true,
        meta: {
          title: 'Cadastro de integração de aplicação',
          backPage: 'integracao',
          applicationKey: 'workspace',
          action: 'create',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Crud.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-integracao',
        props: true,
        meta: {
          title: 'Cadastro de integração de aplicação',
          backPage: 'integracao',
          applicationKey: 'workspace',
          action: 'edit',
          fatherRoute: fatherRouteName,
        },
        component: () => import(/* webpackChunkName: "crud" */ '../Crud.vue'),
      },
    ],
  },
];
