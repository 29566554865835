import Portal from '../Index.vue';

export default [
  {
    path: '/',
    name: 'PortalView',
    component: Portal,
    children: [
      {
        path: '/selecionar-aplicacao',
        name: 'selecionar-aplicacao',
        meta: { applicationKey: 'workspace', action: 'views', ignorePermission: true },
        component: () => import('../ApplicationSelection.vue'),
        props: true,
      },
      {
        path: '/selecionar-empresa',
        name: 'selecionar-empresa',
        meta: { applicationKey: 'workspace', action: 'views', ignorePermission: true },
        component: () => import('../ClientSelection.vue'),
        props: true,
      },
    ],
  },
];
