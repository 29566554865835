import Vue from 'vue';
import Vuex from 'vuex';
import gestao from './gestao';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: { gestao },
});
