const payloadProfile = ({ profile, permissionsList }) => {
  const profileData = {
    id: profile.id,
    enabled: profile.enabled,
    type: profile.type.trim(),
    profileTemplate: permissionsList.flatMap((permission) =>
      permission.profileTemplate.filter(
        (item) => item.id !== 'all' && item.onlyView !== '' && item.onlyView !== null,
      ),
    ),
  };
  if (!permissionsList?.length) {
    return profileData;
  } else {
    return {
      ...profileData,
      application: profile.application,
    };
  }
};

const originalProfile = ({ originalProfile }) => originalProfile;

const originalPermissionsList = ({ originalPermissionsList }) => originalPermissionsList;

const profilePermissionToggles = ({ permissionToggles }) => permissionToggles;

const profileTable = ({ profiles }) => profiles;

export default {
  payloadProfile,
  originalProfile,
  originalPermissionsList,
  profileTable,
  profilePermissionToggles,
};
