import Vue from 'vue';
import VueRouter from 'vue-router';
import { configureBeforeEachRouter } from '@zitrus/utils-service';
import PortalRoutes from '@/views/portal/router';
import UserRoutes from '@/views/user/router';
import ProfileRoutes from '@/views/profile/router';
import MyProfileRoute from '@/views/myProfile/router';
import LgpdRouter from '@/views/lgpd/router';
import IntegrationRouter from '@/views/integration/router';

Vue.use(VueRouter);

const routes = [
  ...PortalRoutes,
  ...UserRoutes,
  ...ProfileRoutes,
  ...MyProfileRoute,
  ...LgpdRouter,
  ...IntegrationRouter,
];

const router = new VueRouter({
  mode: 'history',
  base: '/workspace',
  routes: [
    { path: '/', redirect: '/usuario' },
    ...routes,
    {
      path: '/404',
      component: () => import('../EmptyState.vue'),
      name: 'not-found',
      meta: { emptyState: true, applicationKey: 'workspace', action: 'view' },
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('../EmptyState.vue'),
      name: 'EmptyState',
      meta: { emptyState: true, applicationKey: 'workspace', action: 'view' },
    },
  ],
});

router.beforeEach((to, from, next) => configureBeforeEachRouter(router, to, from, next));

export default router;
