import Vue from 'vue';
import {
  getAplicacao,
  getHistoricoConsentimentos,
  getListaConsentimento,
  getTermoAplicacaoVigente,
  getTermoRecenteService,
  saveTermoAplicacao,
  updateTermoVigenteStatusService,
} from '../../service';
import { getApplicationsByClient } from '@/views/portal/service';
import { formatApps } from '@/utils/clientApplicationsFormat';

const updateProperty = ({ commit, dispatch }, { property, payload }) => {
  if (property === 'loading') {
    dispatch('gestao/loadingProgress/setIsLoading', payload, { root: true });
  }

  commit('GENERIC_MUTATION', { property, payload });
};

const resetState = ({ commit }) => commit('RESET_STATE');

const loadAplicacoes = async ({ commit, dispatch }) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await getApplicationsByClient({
    clientId: Vue.prototype.getLoggedUser()?.clientId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', { property: 'aplicacoes', payload: formatApps(data) });
    },
    onFinally: () => dispatch('updateProperty', { property: 'loading', payload: false }),
  });
};

const loadAplicacao = async ({ commit, dispatch, state }, id) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await getAplicacao({
    id,
    onSuccess: async ({ data }) => {
      await commit('GENERIC_MUTATION', { property: 'aplicacao', payload: data ?? {} });
    },
    onFinally: async () => {
      await dispatch('loadTermoAplicacaoVigente', state.aplicacao.key);

      dispatch('updateProperty', { property: 'loading', payload: false });
    },
  });
};

const loadTermoAplicacaoVigente = async ({ commit, dispatch, state }, applicationKey) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await getTermoAplicacaoVigente({
    applicationKey,
    onSuccess: async ({ data }) => {
      if (!data.enabled) {
        await dispatch('getTermoRecente', applicationKey);
      } else {
        const termo = { ...data, applicationKey: data?.applicationKey ?? applicationKey };

        commit('GENERIC_MUTATION', { property: 'termoVigente', payload: termo });
        commit('GENERIC_MUTATION', {
          property: 'originalTermoVigente',
          payload: structuredClone(termo),
        });
      }
    },
    onFinally: () => {
      if (Object.keys(state.termoVigente).length <= 1) {
        dispatch('updateProperty', { property: 'isEditMode', payload: true });
      }
      dispatch('updateEnabledButtons');

      dispatch('updateProperty', { property: 'loading', payload: false });
    },
  });
};

const loadListaConsentimento = async (
  { commit, dispatch, state },
  { page = 1, size = 10, sort = 'acceptedAt,asc', search = '' } = {},
) => {
  dispatch('updateProperty', { property: 'loadingTable', payload: true });

  await getListaConsentimento({
    applicationId: state.aplicacao.key,
    pagination: {
      page,
      size,
      sort,
      search,
    },
    onSuccess: ({ data }) => {
      const pageWorkspace = data?.['z-workspace']?.page;

      commit('GENERIC_MUTATION', {
        property: 'listaConsentimento',
        payload: data || {},
      });

      commit('GENERIC_MUTATION', {
        property: 'totalPages',
        payload: pageWorkspace?.totalPages,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        payload: pageWorkspace?.totalElements,
      });
    },
    onFinally: () => dispatch('updateProperty', { property: 'loadingTable', payload: false }),
  });
};

const updateTermoAplicacao = async ({ dispatch, getters }) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await saveTermoAplicacao({
    ...getters.payloadTermoVigente,
    onSuccess: async ({ data }) => {
      Vue.$toast.success('Termo publicado com sucesso');

      await dispatch('loadTermoAplicacaoVigente', data.applicationKey);
      await dispatch('updateField', { property: 'content', value: data.content });
      await dispatch('updateEnabledButtons');
      await dispatch('updateProperty', { property: 'isEditMode', payload: false });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro ao publicar o termo');
    },
    onFinally: () => dispatch('updateProperty', { property: 'loading', payload: false }),
  });
};

const updateField = ({ commit, getters }, { property, value }) => {
  const { originalTermoVigente } = getters;

  commit('UPDATE_FIELD', {
    property,
    value,
  });

  commit('GENERIC_MUTATION', {
    property: 'isFormDataDirty',
    payload: originalTermoVigente[property] !== value,
  });
};

const loadHistoricoConsentimentos = async ({ dispatch, state, commit }, usuarioId) => {
  dispatch('updateProperty', { property: 'loadingTableHistorico', payload: true });

  await getHistoricoConsentimentos({
    usuarioId,
    applicationKey: state.aplicacao.key,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'historicoConsentimentos',
        payload: data,
      });
    },
    onFinally: () =>
      dispatch('updateProperty', { property: 'loadingTableHistorico', payload: false }),
  });
};

const updateTermoVigenteStatus = async ({ commit, dispatch }, termoId) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await updateTermoVigenteStatusService({
    termoId,
    onSuccess: ({ data }) => {
      Vue.$toast.success(`Termo ${data?.enabled ? 'ativado' : 'desativado'} com sucesso`);

      commit('UPDATE_FIELD', {
        property: 'enabled',
        value: data?.enabled,
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro ao atualizar o termo');
    },
    onFinally: () => dispatch('updateProperty', { property: 'loading', payload: false }),
  });
};

const getTermoRecente = async ({ commit, dispatch }, applicationKey) => {
  dispatch('updateProperty', { property: 'loading', payload: true });

  await getTermoRecenteService({
    applicationKey,
    onSuccess: ({ data }) => {
      const termo = { ...data, applicationKey: data?.applicationKey ?? applicationKey };

      commit('GENERIC_MUTATION', { property: 'termoVigente', payload: termo });
      commit('GENERIC_MUTATION', {
        property: 'originalTermoVigente',
        payload: structuredClone(termo),
      });
    },
    onFinally: () => dispatch('updateProperty', { property: 'loading', payload: false }),
  });
};

const updateEnabledButtons = ({ commit, state }) => {
  if (Object.keys(state.termoVigente).length > 1) {
    commit('GENERIC_MUTATION', {
      property: 'enabledButtons',
      payload: true,
    });
  } else {
    commit('GENERIC_MUTATION', { property: 'enabledButtons', payload: false });
  }
};

export default {
  loadAplicacoes,
  loadTermoAplicacaoVigente,
  loadAplicacao,
  loadListaConsentimento,
  updateProperty,
  updateTermoAplicacao,
  updateField,
  resetState,
  loadHistoricoConsentimentos,
  updateTermoVigenteStatus,
  getTermoRecente,
  updateEnabledButtons,
};
