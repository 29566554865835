import { zDataApi } from '@/api';
import { createCommandService } from '@/services';

const findCep = async ({ cep, onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zDataApi}/cep/${cep}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const findCities = async ({ city, onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zDataApi}/localidade/municipios/${city ?? ''}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const findStates = async ({ onSuccess, onCustomError, onFinally }) => {
  await createCommandService({
    method: 'get',
    uri: `${zDataApi}/localidade/estados`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

export { findCep, findCities, findStates };
