const setIsLoading = ({ commit }, isLoading) => {
  if (isLoading) {
    commit('ACTIVE_LOADING');
  } else {
    commit('INACTIVE_LOADING');
  }
};

export default {
  setIsLoading,
};
