import Vue from 'vue';
import router from '@/router';
import { toNormalLettersWithoutSpecialChar } from '@/utils/convertingString';
import {
  createProfileService,
  deleteProfileService,
  getProfileById,
  getProfiles,
  getProfileTemplates,
  updateProfileService,
} from '../service';
import { createQueryParams } from '@/utils/queryParam';

const loadProfiles = async ({ commit }, { payload }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getProfiles({
    payload: createQueryParams(payload, null, { useQuestionMark: false }),
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'profiles',
        payload: data ?? [],
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar perfis');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const loadProfileById = async ({ commit }, { id }) => {
  await getProfileById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'profile',
        payload: structuredClone(data),
      });
      commit('GENERIC_MUTATION', {
        property: 'originalProfile',
        payload: structuredClone(data),
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar perfil');
    },
  });
};

const createProfile = async ({ getters, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'isSaving',
    payload: true,
  });

  const { payloadProfile } = getters;

  await createProfileService({
    payload: payloadProfile,
    application: payloadProfile?.application?.key,
    onSuccess: () => {
      Vue.$toast.success('Perfil criado com sucesso.');
      commit('RESET_STATE');

      router.push({ name: 'perfil' });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao criar perfil');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isSaving',
        payload: false,
      });
    },
  });
};

const updateProfile = async ({ getters, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'isSaving',
    payload: true,
  });

  const { payloadProfile } = getters;

  await updateProfileService({
    payload: payloadProfile,
    application: payloadProfile?.application?.key,
    onSuccess: () => {
      Vue.$toast.success('Perfil alterado com sucesso.');
      commit('RESET_STATE');

      router.push({ name: 'perfil' });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao atualizar perfil');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isSaving',
        payload: false,
      });
    },
  });
};

const deleteProfile = async ({ state, commit, dispatch }, { id, application, query }) => {
  await deleteProfileService({
    id,
    application,
    onSuccess: () => {
      Vue.$toast.success('Perfil excluído com sucesso.');

      if (state.profiles.length === 1) {
        router.replace({
          name: 'perfil',
          query: {
            ...query,
            page: query.page,
          },
        });
      }

      dispatch('loadProfiles', query);

      commit('RESET_FORM_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao excluir perfil');
    },
  });
};

const updateField = ({ commit, getters }, { property, value }) => {
  const { originalProfile } = getters;

  commit('UPDATE_FIELD', {
    property,
    value,
  });

  commit('GENERIC_MUTATION', {
    property: 'isFormDataDirty',
    payload: originalProfile[property] !== value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

const loadProfileTemplates = async ({ commit, dispatch, state: { profile } }, applicationId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingProfileTemplates',
    payload: true,
  });

  const isEdit = profile?.id;

  await getProfileTemplates({
    applicationId,
    onSuccess: async ({ data }) => {
      const updatedData = data?.map((item) => ({
        ...item,
        profileTemplate: [
          {
            id: 'all',
            type: 'Todas',
            onlyView: isEdit ? '' : null,
            category: item.category.toLowerCase(),
          },
          ...item.profileTemplates.map((template) => ({
            ...template,
            onlyView: isEdit ? '' : null,
          })),
        ],
      }));

      commit('GENERIC_MUTATION', {
        property: 'permissionsList',
        payload: structuredClone(updatedData),
      });

      profile.profilesTemplate?.forEach((template) => {
        dispatch('updatePermissionSelected', {
          value: template.onlyView,
          id: template.id,
          isLoadingPermissionInfo: true,
        });
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar templates de perfil');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingProfileTemplates',
        payload: false,
      });
    },
  });
};

const updatePermissionSelected = (
  { commit, state: { permissionsList, originalPermissionsList } },
  { id, value, category = '', panel = 0, isLoadingPermissionInfo = false },
) => {
  let changeData = false;
  const currentPermissionsList = isLoadingPermissionInfo
    ? permissionsList
    : originalPermissionsList;

  const updatedPermissionList = permissionsList?.map((permission) => {
    const originalPermission = currentPermissionsList.find(
      ({ category }) => permission.category === category,
    );
    const updatedProfileTemplates = permission.profileTemplate?.map((template) => {
      const isSameCategory =
        toNormalLettersWithoutSpecialChar(template.category) ===
        toNormalLettersWithoutSpecialChar(category);

      if ((id === 'all' && isSameCategory) || template.id === id) {
        if (!changeData) {
          const originalTemplate = originalPermission?.profileTemplate?.find(
            ({ id }) => template.id === id,
          );

          changeData = String(originalTemplate?.onlyView ?? '') !== String(value);
        }

        return {
          ...template,
          onlyView: value,
        };
      }

      return template;
    });

    return {
      ...permission,
      profileTemplate: updatedProfileTemplates,
    };
  });

  const withoutIdAll = updatedPermissionList[panel]?.profileTemplate?.filter(
    (template) => template.id !== 'all',
  );

  const isSameValue = withoutIdAll
    ?.map((template) => template.onlyView)
    .every((val, i, arr) => {
      return val === arr[0];
    });

  if (isSameValue) {
    updatedPermissionList[panel].profileTemplate[0].onlyView = withoutIdAll[0]?.onlyView;
  }

  commit('GENERIC_MUTATION', {
    property: 'permissionsList',
    payload: updatedPermissionList,
  });

  if (!isLoadingPermissionInfo) {
    commit('GENERIC_MUTATION', {
      property: 'isFormDataDirty',
      payload: changeData,
    });
  } else {
    commit('GENERIC_MUTATION', {
      property: 'originalPermissionsList',
      payload: structuredClone(updatedPermissionList),
    });
  }
};

export default {
  loadProfiles,
  loadProfileById,
  createProfile,
  updateProfile,
  deleteProfile,
  updateField,
  resetState,
  loadProfileTemplates,
  updatePermissionSelected,
};
