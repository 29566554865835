<template>
  <v-layout column class="main-container">
    <loading-progress />
    <div class="main-container__body">
      <v-btn v-if="$route.meta.backPage && !showActions" text @click="$emit('back')">
        <v-icon>mdi-keyboard-return</v-icon>
        Voltar
      </v-btn>
      <div v-if="$route.meta.title || $route.meta.subtitle" class="breadcrumb-header mb-4">
        <h1 v-if="$route.meta.title">{{ $route.meta.title }}</h1>
        <h2 v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</h2>
      </div>

      <div v-if="showActions">
        <slot name="actions" />
      </div>

      <slot name="content" />
    </div>

    <div v-if="showFooter">
      <v-footer app class="px-12" color="white" height="72">
        <slot name="footer" />
      </v-footer>
    </div>
  </v-layout>
</template>

<script>
import LoadingProgress from '@/views/loading-progress/LoadingProgress.vue';

export default {
  name: 'MainContainer',
  props: {
    showActions: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: false },
  },
  components: {
    LoadingProgress,
  },
  data() {
    return {
      marginFooter: `--margin-footer: ${this.showFooter ? '72px' : '0px'}`,
    };
  },
  mounted() {
    if (document.querySelector('#z-gestao')) {
      document.querySelector('#z-gestao').style = this.marginFooter;
    }
  },
};
</script>

<style lang="scss" scoped>
#z-gestao {
  .v-application--wrap {
    margin-bottom: var(--margin-footer) !important;

    .main-container {
      background-color: theme('colors.white');
      min-height: 0;
      flex: 1 0 0rem;
      overflow-y: auto !important;
      margin: 12px;

      &__body {
        margin: 30px 44px;

        > button {
          color: theme('colors.primary-blue-medium');
          padding: 0;
          height: auto;
          margin-bottom: 20px;
        }

        .breadcrumb-header {
          h1 {
            font-weight: 600;
            font-size: 20px;
          }

          h2 {
            font-weight: 700;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
