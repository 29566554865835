import { zAuditApi, zBffApi, zPermissionApi, zWorkspaceApi } from '@/api';
import { createCommandService } from '@/services';

const getAuditActions = async ({ searchString, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zAuditApi}/record_object/paged${searchString}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getTermoAplicacaoVigente = ({ applicationKey, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/aviso-privacidade/${applicationKey}/vigente`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getAplicacao = ({ id, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getListaConsentimento = ({
  applicationId,
  pagination: { page, size, sort, search },
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${zBffApi}/termos-e-usuarios/${applicationId}?page=${
      page - 1
    }&size=${size}&sort=${sort}&filter=${search}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const saveTermoAplicacao = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    uri: `${zWorkspaceApi}/aviso-privacidade`,
    payload,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getHistoricoConsentimentos = ({
  usuarioId,
  applicationKey,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/aviso-privacidade/aceite/usuario/${usuarioId}/historico/${applicationKey}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateTermoVigenteStatusService = ({ termoId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${zWorkspaceApi}/aviso-privacidade/enabled/${termoId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getTermoRecenteService = ({ applicationKey, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/aviso-privacidade/${applicationKey}/recente`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getAuditActions,
  getTermoAplicacaoVigente,
  getAplicacao,
  getListaConsentimento,
  saveTermoAplicacao,
  getHistoricoConsentimentos,
  updateTermoVigenteStatusService,
  getTermoRecenteService,
};
