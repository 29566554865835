<template>
  <v-expansion-panels :value="0" accordion class="custom-expansion-panel mb-3" flat>
    <v-expansion-panel>
      <v-expansion-panel-header>Histórico de ações</v-expansion-panel-header>
      <v-expansion-panel-content class="pt-4">
        <v-form ref="formQueryAudit" v-model="isValidForm" lazy-validation>
          <v-row>
            <v-col cols="12" lg="3" md="4">
              <v-autocomplete
                v-model="audit.filter.application"
                persistent-placeholder
                item-text="title"
                item-value="key"
                label="Aplicação*"
                placeholder="Preencher"
                :items="audit.applications"
                :loading="isLoadingApps"
                :rules="[required]"
                @change="(value) => updateFilterField({ property: 'application', value })"
              />
            </v-col>
            <v-col cols="12" lg="3" md="6">
              <v-text-field
                v-model="audit.filter.entityName"
                persistent-placeholder
                label="Entidade*"
                placeholder="Preencher"
                :loading="isLoadingEntities"
                :rules="[required, letterAndNumber]"
                @change="(value) => updateFilterField({ property: 'entityName', value })"
              />
            </v-col>
            <v-col cols="12" lg="3" md="6">
              <v-text-field
                v-model="audit.filter.objectId"
                persistent-placeholder
                label="ID"
                placeholder="Preencher"
                type="number"
                @change="(value) => updateFilterField({ property: 'objectId', value })"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4">
              <v-autocomplete
                v-model="audit.filter.event"
                persistent-placeholder
                label="Ação"
                placeholder="Preencher"
                :items="audit.actions"
                @change="(value) => updateFilterField({ property: 'event', value })"
              />
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-text-field
                v-model="audit.filter.previousValue"
                persistent-placeholder
                label="Valor antigo"
                placeholder="Preencher"
                @change="(value) => updateFilterField({ property: 'previousValue', value })"
              />
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-text-field
                v-model="audit.filter.currentValue"
                persistent-placeholder
                label="Valor novo"
                placeholder="Preencher"
                @change="(value) => updateFilterField({ property: 'currentValue', value })"
              />
            </v-col>
            <v-col cols="12" lg="4" md="4">
              <v-text-field
                v-model="audit.filter.author"
                persistent-placeholder
                label="Nome do usuário"
                placeholder="Preencher"
                @change="(value) => updateFilterField({ property: 'author', value })"
              />
            </v-col>
            <v-col cols="7" lg="4" md="4">
              <v-menu
                v-model="startDateMenu"
                offset-y
                min-width="auto"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateText"
                    v-bind="attrs"
                    v-on="on"
                    v-mask="'##/##/####'"
                    persistent-placeholder
                    append-icon="mdi-calendar"
                    label="Data inicial*"
                    placeholder="00/00/0000"
                    :rules="[required, isValidDate, minDate(startDateText, endDateText)]"
                    @change="(value) => onChangeTextDate(value, 'startDate')"
                  />
                </template>
                <v-date-picker
                  v-model="audit.filter.startDate"
                  no-title
                  min="1950-01-01"
                  :active-picker.sync="activeInitialDatePicker"
                  :rules="[required, isValidDate]"
                  @input="(value) => onChangeDate(value, 'startDate')"
                />
              </v-menu>
            </v-col>
            <v-col cols="5" lg="2" md="2">
              <v-text-field
                v-model="audit.filter.starHour"
                v-mask="'##:##'"
                persistent-placeholder
                label="Hora inicial"
                placeholder="00:00"
                :disabled="!isValidDate(audit.filter.startDate)"
                :rules="[(value) => isValidTime(value, { acceptEmptyData: true })]"
                @change="(value) => updateFilterField({ property: 'starHour', value })"
              />
            </v-col>

            <v-col cols="7" lg="4" md="4">
              <v-menu
                v-model="endDateMenu"
                offset-y
                min-width="auto"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateText"
                    v-bind="attrs"
                    v-on="on"
                    v-mask="'##/##/####'"
                    persistent-placeholder
                    append-icon="mdi-calendar"
                    label="Data final*"
                    placeholder="00/00/0000"
                    :rules="[required, isValidDate, maxDate(startDateText, endDateText)]"
                    @change="(value) => onChangeTextDate(value, 'endDate')"
                  />
                </template>
                <v-date-picker
                  v-model="audit.filter.endDate"
                  no-title
                  min="1950-01-01"
                  :active-picker.sync="activeFinalDatePicker"
                  :rules="[required, isValidDate]"
                  @change="(value) => onChangeDate(value, 'endDate')"
                />
              </v-menu>
            </v-col>
            <v-col cols="5" lg="2" md="2">
              <v-text-field
                v-model="audit.filter.endHour"
                v-mask="'##:##'"
                persistent-placeholder
                label="Hora final"
                placeholder="00:00"
                :disabled="!isValidDate(audit.filter.endDate)"
                :rules="[(value) => isValidTime(value, { acceptEmptyData: true })]"
                @change="(value) => updateFilterField({ property: 'endHour', value })"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row dense justify="end">
          <v-col cols="auto">
            <v-btn class="btn btn__outlined" outlined @click="searchAudit">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  isValidDate,
  minDate,
  maxDate,
  isValidTime,
  required,
  letterAndNumber,
} from '@/utils/validations';
import { formatDate } from '@/utils/date';
import { DEFAULT_BRAZILIAN_FORMAT, DEFAULT_DATE_FORMAT } from '@/utils/dateFormats';
import { toCamel } from '@/utils/convertingString';

export default {
  name: 'QueryFilter',
  data() {
    return {
      startDateMenu: false,
      startDateText: '',
      endDateMenu: false,
      endDateText: '',
      activeInitialDatePicker: '',
      activeFinalDatePicker: '',
      isValidForm: false,
      isValidDate,
      isValidTime,
      minDate,
      maxDate,
      letterAndNumber,
    };
  },
  async created() {
    await this.loadApplications();
  },
  computed: {
    ...mapState('gestao/lgpd/audit', [
      'audit',
      'isSearching',
      'isLoadingApps',
      'isLoadingEntities',
    ]),
  },
  methods: {
    required,
    ...mapActions('gestao/lgpd/audit', [
      'findAuditActions',
      'loadApplications',
      'updateFilterField',
    ]),
    async searchAudit() {
      const isValidForm = this.$refs.formQueryAudit.validate();

      if (isValidForm) {
        return this.findAuditActions();
      }
    },
    onChangeDate(value, property) {
      this.updateFilterField({ property, value });
      this[`${toCamel(property)}Text`] = formatDate(
        value,
        DEFAULT_BRAZILIAN_FORMAT,
        DEFAULT_DATE_FORMAT,
      );
    },
    onChangeTextDate(value, property) {
      if (isValidDate(value)) {
        this.updateFilterField({
          property,
          value: formatDate(value, DEFAULT_DATE_FORMAT, DEFAULT_BRAZILIAN_FORMAT),
        });
      }
    },
  },
};
</script>
