import { zPermissionApi } from '@/api';
import { createCommandService } from '@/services';

const getApplicationByKey = async ({ key, onSuccess, onCustomError }) => {
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/chave/${key}`,
    onSuccess,
    onCustomError,
  });
};

const getApplications = async ({ query, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: query
      ? `${zPermissionApi}/aplicacao/paged?page=${query.page - 1}&size=${query.size}`
      : `${zPermissionApi}/aplicacao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getApplicationsUser = async ({ userId, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/usuario/${userId}/aplicacao`,
    onSuccess,
    onCustomError,
  });

const getApplicationById = async ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${id}`,
    onSuccess,
    onCustomError,
  });

const getApplicationPermissions = async ({ applicationId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${applicationId}/permissao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createApplicationService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${zPermissionApi}/aplicacao`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteApplicationService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${zPermissionApi}/aplicacao/${id}`,
    onSuccess,
    onCustomError,
  });

const updateApplicationService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${zPermissionApi}/aplicacao/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getApplicationByKey,
  getApplications,
  getApplicationById,
  getApplicationPermissions,
  getApplicationsUser,
  createApplicationService,
  deleteApplicationService,
  updateApplicationService,
};
