<template>
  <main-container show-actions>
    <template v-slot:actions>
      <v-layout class="mb-6">
        <v-btn outlined class="btn btn__outlined" @click="newProfile()"> Incluir novo</v-btn>
        <v-spacer />
        <v-text-field
          v-model="searchValue"
          clearable
          hide-details
          append-icon="mdi-magnify"
          class="input-search"
          placeholder="Pesquisar"
        />
      </v-layout>
    </template>

    <template v-slot:content>
      <v-tabs v-model="selectedTab" show-arrows @change="dispatchLoad">
        <v-tab v-for="tab of tabs" :key="tab.key">
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <custom-data-table-card
        v-bind="{ items: transformObjectToGrid, options: { groupBy: 'subtitle' } }"
        :loading="loadingTable"
        :search="searchValue"
        @click="edit"
      />
    </template>
  </main-container>
</template>

<script>
import MainContainer from '@/components/MainContainer.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import CustomDataTableCard from '@/components/CustomDataTableCard.vue';

export default {
  components: { MainContainer, CustomDataTableCard },
  name: 'profileQuery',
  data() {
    return {
      searchValue: null,
      selectedTab: null,
      tabs: [
        { key: 0, title: 'Todos' },
        { key: 1, title: 'Ativos', enabled: true },
        { key: 2, title: 'Inativos', enabled: false },
      ],
    };
  },
  computed: {
    ...mapGetters('gestao/profile', ['profileTable']),
    ...mapState('gestao/profile', ['loadingTable']),
    transformObjectToGrid() {
      return Array.isArray(this.profileTable)
        ? this.profileTable?.map((profile) => ({
            id: profile.id,
            title: profile.type,
            subtitle: profile.application.title,
            active: profile.enabled,
            application: profile.application,
          }))
        : [];
    },
  },
  methods: {
    ...mapActions('gestao/profile', ['loadProfiles']),
    async dispatchLoad(tabIndex) {
      let payload = {};
      const enabled = this.tabs.find((tab) => tab.key === tabIndex)?.enabled;
      if (enabled?.toString()?.length > 0) {
        payload = { enabled };
      }

      await this.loadProfiles({ payload });
    },
    edit(profile) {
      this.$router.push({
        name: 'edit-perfil',
        params: { id: profile.id },
      });
    },
    newProfile() {
      this.$router.push({
        name: 'create-perfil',
      });
    },
  },
};
</script>

<style lang="scss">
#z-gestao {
  .v-application--wrap .main-container {
    &__body {
      margin: 24px 44px 44px 30px;
    }
  }
}
</style>
