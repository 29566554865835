const forms = {
  id: null,
  description: '',
  featureName: '',
  parentId: null,
  path: '',
  type: '',
};

export default () => ({
  permissions: [],
  types: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingTypes: false,
  isSaving: false,
  isSavingAndNew: false,
  permission: { ...forms },
  initialState: {
    id: null,
    description: '',
    featureName: '',
    parentId: null,
    path: '',
    type: '',
  },
});
