const defaultOptions = { useQuestionMark: true };

const createQueryParams = (filters = {}, pageable = null, opts = defaultOptions) => {
  const { useQuestionMark } = opts;
  const urlSearchParams = new URLSearchParams('');

  if (Object.keys(filters ?? {}).length > 0) {
    Object.keys(filters).forEach((key) => {
      const value = String(filters[key] != null ? filters[key] : '');

      if (value !== '' && value?.length > 0) {
        urlSearchParams.append(key, value);
      }
    });
  }

  if (pageable) {
    const page = pageable?.page > 0 ? pageable?.page - 1 : 0;

    urlSearchParams.append('page', String(page));
    urlSearchParams.append('size', String(pageable?.itemsPerPage ?? 10));
  }

  return urlSearchParams.toString().length > 0
    ? `${useQuestionMark ? '?' : ''}${urlSearchParams}`
    : '';
};

export { createQueryParams };
