import Vue from 'vue';
import {
  acceptPrivacyNoticeService,
  getAtivePrivacyNotice,
  getPrivacyNoticeAcceptance,
} from '../service';

const loadPrivacyNoticeAcceptance = async ({ commit, dispatch }, applicationKey) => {
  commit('GENERIC_MUTATION', {
    property: 'applicationKey',
    payload: applicationKey,
  });

  await getPrivacyNoticeAcceptance({
    applicationKey,
    onSuccess: ({ data }) => {
      if (data) {
        dispatch('gestao/portal/selectApplication', applicationKey, {
          root: true,
        });
      } else {
        dispatch('loadAtivePrivacyNotice');
      }
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro ao verificar se o usuário aceitou os termos');
    },
  });
};

const loadAtivePrivacyNotice = async ({ state, commit, dispatch }) => {
  await getAtivePrivacyNotice({
    applicationKey: state.applicationKey,
    onSuccess: ({ data }) => {
      if (data.enabled) {
        commit('GENERIC_MUTATION', {
          property: 'showPrivacyNoticeDialog',
          payload: true,
        });

        commit('GENERIC_MUTATION', {
          property: 'ativePrivacyNotice',
          payload: data,
        });
      } else {
        dispatch('gestao/portal/selectApplication', state.applicationKey, {
          root: true,
        });
      }
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro ao carregar os termos de privacidade');
    },
  });
};

const acceptPrivacyNotice = async ({ state, commit, dispatch }, privacyNoticeId) => {
  await acceptPrivacyNoticeService({
    payload: { privacyNoticeId },
    onSuccess: async () => {
      await dispatch('gestao/portal/selectApplication', state.applicationKey, {
        root: true,
      });

      commit('GENERIC_MUTATION', {
        property: 'showPrivacyNoticeDialog',
        payload: false,
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro ao tentar aceitar os termos');
    },
  });
};

const updateField = ({ commit }, { property, payload }) => {
  commit('GENERIC_MUTATION', {
    property,
    payload,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadAtivePrivacyNotice,
  loadPrivacyNoticeAcceptance,
  acceptPrivacyNotice,
  updateField,
  resetState,
};
