import { zLicenceApi, zBffApi } from '@/api';
import { createCommandService } from '@/services';

const getCompanies = async ({ query, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: query
      ? `${zLicenceApi}/cliente-usuario?userId=${query.userId}&enabled=true&status=ENABLED`
      : `${zLicenceApi}/cliente-usuario`,
    onSuccess,
    onCustomError,
  });

const getCompanyById = async ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'get',
    uri: `${zLicenceApi}/cliente/${id}`,
    onSuccess,
    onCustomError,
  });

const getLastCompanyAccessByUserId = async ({ userId, onSuccess, onCustomError }) => {
  await createCommandService({
    method: 'get',
    uri: `${zBffApi}/usuario-cliente-recente/${userId}`,
    onSuccess,
    onCustomError,
  });
};

const createCompanyService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${zLicenceApi}/cliente`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteCompanyService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${zLicenceApi}/cliente/${id}`,
    onSuccess,
    onCustomError,
  });

const updateCompanyService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${zLicenceApi}/cliente/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getCompanies,
  getCompanyById,
  createCompanyService,
  getLastCompanyAccessByUserId,
  deleteCompanyService,
  updateCompanyService,
};
