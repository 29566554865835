import Vue from 'vue';

export default {
  ACTIVE_LOADING(state) {
    Vue.set(state, 'isLoading', true);
  },
  INACTIVE_LOADING(state) {
    Vue.set(state, 'isLoading', false);
  },
};
