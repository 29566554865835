import moment from 'moment';
import { formatDateRestore } from './date';
import { DEFAULT_DATE_TIME_FORMAT_FILTER, DEFAULT_DATE_FORMAT } from './dateFormats';

const isValidDate = (value, { acceptEmptyData } = false) => {
  const isValidDate =
    value?.length === DEFAULT_DATE_FORMAT.length &&
    moment(formatDateRestore(value), DEFAULT_DATE_FORMAT).isValid();

  return isValidDate || (acceptEmptyData && !value) || 'Data inválida';
};

const minDate = (startDate, endDate, { acceptEmptyStartDate, acceptEmptyEndDate } = false) => {
  const startDateRestored = formatDateRestore(startDate);
  const endDateRestored = formatDateRestore(endDate);

  return (
    new Date(startDateRestored) <= new Date(endDateRestored) ||
    (acceptEmptyStartDate && !startDate) ||
    (acceptEmptyEndDate && !endDate) ||
    'Precisa ser menor ou igual a data final'
  );
};

const maxDate = (startDate, endDate, { acceptEmptyStartDate, acceptEmptyEndDate } = false) => {
  const startDateRestored = formatDateRestore(startDate);
  const endDateRestored = formatDateRestore(endDate);

  return (
    new Date(endDateRestored) >= new Date(startDateRestored) ||
    (acceptEmptyStartDate && !startDate) ||
    (acceptEmptyEndDate && !endDate) ||
    'Precisa ser maior ou igual a data inicial'
  );
};

const isValidTime = (value, { acceptEmptyData } = false) => {
  const isValidDate =
    value?.length === DEFAULT_DATE_TIME_FORMAT_FILTER.length &&
    value !== '24:00' &&
    moment(value, DEFAULT_DATE_TIME_FORMAT_FILTER).isValid();

  return isValidDate || (acceptEmptyData && !value) || 'Hora inválida';
};

const required = (value) => !!value || 'Campo obrigatório';

const letterAndNumber = (value) => /^[a-zA-Z0-9]*$/.test(value) || 'Apenas letras e números';

const validateApplication = (value) => {
  if (!value || Object.keys(value)?.length === 0) {
    return 'Campo obrigatório';
  }

  return true;
};

export {
  isValidDate,
  minDate,
  maxDate,
  isValidTime,
  required,
  letterAndNumber,
  validateApplication,
};
