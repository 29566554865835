import { zPermissionApi } from '@/api';
import { createCommandService } from '@/services';

const reloadUserPermissions = async ({ applicationKey, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${applicationKey}/usuario-perfil/carregar/permissoes`,
    onCustomError,
    onSuccess,
    onFinally,
  });

export { reloadUserPermissions };
