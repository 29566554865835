import Vue from 'vue';
import {
  createIntegrationService,
  getAuthenticationTypes,
  getIntegrationById,
  getIntegrations,
  getIntegrationTypes,
  updateIntegrationService,
} from '../service';
import { formatApps } from '@/utils/clientApplicationsFormat';
import { getApplicationsByClient } from '@/views/portal/service';

const loadIntegrations = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getIntegrations({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'integrations',
        payload: data ?? {},
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      }),
  });
};

const loadApplications = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await getApplicationsByClient({
    clientId: Vue.prototype.getLoggedUser()?.clientId,
    onSuccess: ({ data }) =>
      commit('GENERIC_MUTATION', {
        property: 'applications',
        payload: formatApps(data, true),
      }),
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const loadIntegrationTypes = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await getIntegrationTypes({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'integrationTypes',
        payload: data ?? [],
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const loadAuthenticationTypes = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await getAuthenticationTypes({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'authenticationTypes',
        payload: data ?? [],
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const loadIntegrationData = async ({ commit }, integrationId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await getIntegrationById({
    integrationId,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'payload',
        payload: data ?? [],
      });

      commit('GENERIC_MUTATION', {
        property: 'initialState',
        payload: structuredClone(data ?? []),
      });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const createIntegration = async ({ getters, commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await createIntegrationService({
    payload: getters.payloadCrud,
    onSuccess: () => {
      Vue.$toast.success('Integração cadastrada com sucesso');
      Vue.router.push({ name: 'integracao' });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const updateIntegration = async ({ commit, getters }, integrationId) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingForm',
    payload: true,
  });

  await updateIntegrationService({
    payload: getters.payloadCrud,
    integrationId,
    onSuccess: () => {
      commit('RESET_STATE');

      Vue.$toast.success('Integração alterada com sucesso');
      Vue.router.push({ name: 'integracao' }).catch(() => {});
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro na requisição');
    },
    onFinally: () =>
      commit('GENERIC_MUTATION', {
        property: 'loadingForm',
        payload: false,
      }),
  });
};

const updateField = ({ commit }, { object, property, value }) =>
  commit('UPDATE_FIELD', {
    object,
    property,
    value: value || null,
  });

const resetPayload = ({ commit }) => commit('RESET_PAYLOAD');

const resetCredentials = ({ commit }) => commit('RESET_CREDENTIALS');

export default {
  loadIntegrations,
  loadApplications,
  loadIntegrationTypes,
  loadAuthenticationTypes,
  loadIntegrationData,
  createIntegration,
  updateIntegration,
  updateField,
  resetPayload,
  resetCredentials,
};
