import { formatDate } from '@/utils/date';

const aplicacoes = ({ aplicacoes }) =>
  aplicacoes.map((aplicacao) => ({
    ...aplicacao,
    subtitle: aplicacao.name,
    active: aplicacao.enabled,
    picture: aplicacao?.image?.content,
    hideAvatarStatus: true,
  }));

const listaConsentimento = ({ listaConsentimento }) => {
  const payload = [];

  listaConsentimento['z-workspace']?.page?.content.forEach((zWorkspace, index) => {
    const zLogin = listaConsentimento['z-login'].find((login) => login.id === zWorkspace.userId);

    if (zLogin) {
      payload.push({
        key: index,
        id: zLogin.id,
        login: zLogin.login,
        status: zLogin.active ? 'ATIVO' : 'INATIVO',
        version: zWorkspace.version,
        dataAceite: zWorkspace.acceptedAt ? formatDate(zWorkspace.acceptedAt) : '',
      });
    }
  });

  return payload;
};

const payloadTermoVigente = ({ termoVigente }) => ({
  payload: {
    applicationKey: termoVigente.applicationKey,
    content: termoVigente.content,
  },
});

const historicoConsentimento = ({ historicoConsentimentos }) =>
  historicoConsentimentos.map((historicoConsentimento) => ({
    ...historicoConsentimento,
    acceptedAt: formatDate(historicoConsentimento.acceptedAt),
  }));

const hasHistoricoConsentimento = ({ historicoConsentimentos }) => {
  return historicoConsentimentos.length > 0;
};

const originalTermoVigente = ({ originalTermoVigente }) => ({
  ...originalTermoVigente,
  content: originalTermoVigente.content || '',
});

export default {
  aplicacoes,
  listaConsentimento,
  originalTermoVigente,
  payloadTermoVigente,
  historicoConsentimento,
  hasHistoricoConsentimento,
};
