const api =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_API : process.env.ZLOGIN_URL;

const zPermissionApi =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ZPERMISSION
    : process.env.ZPERMISSION_URL;

const zLicenceApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZLICENCE : process.env.ZLICENCE_URL;

const zDataApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZDATA : process.env.ZDATA_URL;

const zWorkspaceApi =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ZWORKSPACE
    : process.env.ZWORKSPACE_URL;

const zAuditApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZAUDIT : process.env.ZAUDIT_URL;

const zBffApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZBFF : process.env.ZBFF_URL;

export { api, zPermissionApi, zLicenceApi, zDataApi, zWorkspaceApi, zAuditApi, zBffApi };
