export default [
  {
    path: '/meu-perfil',
    name: 'meu-perfil',
    props: true,
    meta: {
      title: 'Gerenciar perfil',
      subtitle: 'Preferências da minha conta',
      backPage: 'meu-perfil',
      applicationKey: 'workspace',
      action: 'view',
    },
    component: () => import('../Index.vue'),
  },
];
