<template>
  <main-container show-actions>
    <template v-slot:actions>
      <v-layout class="mb-6">
        <v-btn outlined class="btn btn__outlined" @click="newUser()">Incluir novo</v-btn>
        <v-spacer />
        <v-text-field
          v-model="searchValue"
          clearable
          hide-details
          append-icon="mdi-magnify"
          class="input-search"
          placeholder="Pesquisar"
        />
      </v-layout>
    </template>

    <template v-slot:content>
      <v-tabs v-model="selectedTab" show-arrows @change="dispatchLoad">
        <v-tab v-for="tab of tabs" :key="tab.key">
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <custom-data-table-card
        show-divider
        :items="userTable"
        :loading="loadingTable"
        :search="searchValue"
        @click="edit"
      />
    </template>
  </main-container>
</template>

<script>
import MainContainer from '@/components/MainContainer.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import CustomDataTableCard from '@/components/CustomDataTableCard.vue';

export default {
  components: { MainContainer, CustomDataTableCard },
  name: 'UserQuery',
  computed: {
    ...mapState('gestao/application', ['key']),
    ...mapState('gestao/user', ['loadingTable']),
    ...mapGetters('gestao/user', ['userTable']),
  },
  data() {
    return {
      searchValue: null,
      selectedTab: null,
      tabs: [
        { key: 0, title: 'Todos', value: 'ALL' },
        { key: 1, title: 'Ativos', value: 'ENABLED' },
        { key: 2, title: 'Inativos', value: 'DISABLED' },
        { key: 3, title: 'Bloqueados', value: 'BLOCKED' },
        { key: 4, title: 'Externos', value: 'EXTERNAL' },
      ],
    };
  },
  methods: {
    ...mapActions('gestao/user', ['loadUsersData']),
    async dispatchLoad(tabIndex) {
      await this.loadUsersData({
        type: this.tabs.find((tab) => tab.key === tabIndex)?.value || 'ALL',
        clientId: this.getLoggedUser()?.clientId,
      });
    },
    edit(user) {
      this.$router.push({
        name: 'edit-usuario',
        params: { id: user.id, emailNotValid: false },
        query: {
          hideMenu: true,
        },
      });
    },
    newUser() {
      this.$router.push({
        name: 'create-usuario',
        query: { hideMenu: true },
      });
    },
  },
};
</script>
