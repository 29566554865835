import { zWorkspaceApi } from '@/api';
import { createCommandService } from '@/services';

const getAtivePrivacyNotice = async ({ applicationKey, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/aviso-privacidade/${applicationKey}/vigente`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getPrivacyNoticeAcceptance = async ({
  applicationKey,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${zWorkspaceApi}/aviso-privacidade/aceite/versao_atual/${applicationKey}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const acceptPrivacyNoticeService = async ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    payload,
    uri: `${zWorkspaceApi}/aviso-privacidade/aceite`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export { getAtivePrivacyNotice, getPrivacyNoticeAcceptance, acceptPrivacyNoticeService };
