const payloadApplication = ({ application }) => application;

const applicationTable = ({ applications }) => applications;

const applicationPermissionTable = ({ applicationPermissions }) => applicationPermissions;

export default {
  payloadApplication,
  applicationTable,
  applicationPermissionTable,
};
