const payloadUser = ({ user }) => user;

const payloadEditUser = ({ user, isExternalUser }) => ({
  id: user.id,
  name: user.name,
  email: user.email,
  picture: user.picture,
  active: user.active,
  isExternal: isExternalUser,
  isBlocked: user.status === 'BLOQUEADO' && user.swithStatus,
});

const userTable = ({ users }) =>
  Array.isArray(users)
    ? users.map((user) => ({
        id: user.id,
        title: user.name,
        subtitle: user.email,
        active: user.active,
        picture: user.picture,
        ...user,
      }))
    : [];
const profilesTable = ({ profiles }) => profiles;
const applicationsTable = ({ applications }) => applications;
const applicationsSelected = ({ user }) => user?.applicationsSelected;
const profilesSelected = ({ user }) => user?.profilesSelected;

const fieldErrors = ({ fieldErrors }) => fieldErrors;

const getApplicationsAvailable =
  ({ user, applications }) =>
  (applicationKey) => {
    if (!applicationKey) {
      const parametrizedApplications =
        user?.accessPermissions?.map((item) => item?.application?.key) ?? [];

      return applications?.filter((item) => !parametrizedApplications?.includes(item.key));
    }

    return applications.filter((item) => item.key === applicationKey);
  };

const getProfilesByApplication =
  ({ profiles }) =>
  (applicationKey) =>
    !applicationKey
      ? []
      : profiles?.filter((profile) => profile?.application?.key === applicationKey);

const payloadAccessPermissions = ({ user }) => {
  let tempValue = {};
  const selectedProfiles = user?.accessPermissions ?? [];

  for (const profile of selectedProfiles) {
    if (Object.keys(profile)?.length === 0) continue;

    tempValue[profile.application.key] = [
      ...(tempValue[profile.application.key] ?? []),
      profile.id,
    ];
  }

  return Object.keys(tempValue)?.length === 0
    ? []
    : Object.keys(tempValue).map((key) => {
        return { key, profiles: tempValue[key] };
      });
};

export default {
  payloadUser,
  payloadEditUser,
  userTable,
  profilesTable,
  applicationsTable,
  applicationsSelected,
  fieldErrors,
  profilesSelected,
  payloadAccessPermissions,
  getApplicationsAvailable,
  getProfilesByApplication,
};
