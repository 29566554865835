<template>
  <main-container show-actions>
    <template v-slot:content>
      <query-filter ref="queryFilter" />

      <v-row class="mt-4">
        <v-col>
          <h2 class="font-weight-bold">{{ gridTextHeader }}</h2>
          <v-divider class="mb-3" />

          <v-data-table
            disable-sort
            :footer-props="{
              itemsPerPageOptions: audit.grid.pageable.itemsPerPageChoice,
              showFirstLastPage: true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
            }"
            :headers="audit.grid.header"
            :items="audit.records"
            :items-per-page="audit.grid.pageable.itemsPerPage"
            :loading="isSearching"
            :page="audit.grid.pageable.page"
            :server-items-length="audit.grid.pageable.size"
            @update:options="updateOptionsGrid($event)"
          >
            <template v-slot:item.date="{ item }">
              {{ formatDateTime(item?.date ?? '') }}
            </template>
            <template v-slot:item.objectId="{ item }">
              {{ item?.recordObject?.objectId ?? '' }}
            </template>
            <template v-slot:item.action="{ item }">
              {{ formatAction(item?.recordObject?.event ?? '') }}
            </template>
            <template v-slot:item.previousValue="{ item }">
              {{ JSON.stringify(item?.recordObject?.previousEntityRecords ?? {}) }}
            </template>
            <template v-slot:item.currentValue="{ item }">
              {{ JSON.stringify(item?.recordObject?.currentEntityRecords ?? {}) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </main-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainContainer from '@/components/MainContainer.vue';
import QueryFilter from '@/views/lgpd/audit/QueryFilter.vue';
import { formatDateTime } from '@/utils/date';
import { toSentence } from '@/utils/convertingString';

export default {
  name: 'AuditQuery',
  components: {
    MainContainer,
    QueryFilter,
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    ...mapState('gestao/lgpd/audit', ['audit', 'isSearching']),
    gridTextHeader() {
      const application = this.audit.applications.find(
        ({ key }) => key === this.audit.filter.application,
      );
      const hasApp = application?.title != null && application.title !== '';
      const hasEntity = this.audit.filter.entityName != null && this.audit.filter.entityName !== '';

      if (hasApp && !hasEntity) {
        return application?.title;
      }

      if (!hasApp && hasEntity) {
        return this.audit.filter.entityName;
      }

      return hasApp && hasEntity
        ? `${application?.title || ''} | ${this.audit.filter.entityName || ''}`
        : '';
    },
    formQueryAudit() {
      return this.$refs.queryFilter?.$refs?.formQueryAudit;
    },
  },
  mounted() {
    this.mounted = true;
    this.formQueryAudit.reset();
    this.resetState();
  },
  methods: {
    ...mapActions('gestao/lgpd/audit', ['findAuditActions', 'updatePageableField', 'resetState']),
    formatDateTime,
    formatAction(action) {
      switch (action) {
        case 'INSERT':
          return 'Inclusão';
        case 'UPDATE':
          return 'Edição';
        case 'DELETE':
          return 'Exclusão';
        default:
          return toSentence(action);
      }
    },
    updateOptionsGrid(options) {
      if (!this.mounted || this.isSearching) {
        return;
      }

      this.updatePageableField({ property: 'page', value: options.page });
      this.updatePageableField({ property: 'itemsPerPage', value: options.itemsPerPage });

      const isValidForm = this.formQueryAudit.validate();
      if (!isValidForm) {
        this.$toast.error('Existem campos inconsistentes no formulário.');
        return;
      }

      return this.findAuditActions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/table.scss';

.custom-expansion-panel {
  :deep(.v-expansion-panel:not(:last-of-type)) {
    margin-bottom: 8px !important;
  }

  :deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
  }

  :deep(.v-expansion-panel-header) {
    padding: 0 0 0 2px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    min-height: 40px !important;
    color: theme('colors.primary-blue-base') !important;
    border-bottom: 1px solid theme('colors.primary-blue-base');

    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;

    &:hover {
      background-color: theme('colors.neutral-gray-low');
    }
  }
}
</style>
