const getClients =
  ({ portal }) =>
  (filter = '') => {
    const { clients, showAllClients } = portal;
    const hasFilter = filter && filter.length > 0;

    if (!hasFilter && !showAllClients) {
      return clients.length > 8 ? clients.slice(0, 8) : clients;
    }

    return clients.filter((item) =>
      hasFilter ? item.clientName.toLowerCase().startsWith(filter) : item,
    );
  };

const getLastAccessClients = ({ portal }) => {
  const { lastAccessClients } = portal;

  return lastAccessClients;
};

export default {
  getClients,
  getLastAccessClients,
};
