const form = {
  name: '',
  email: '',
  actualPassword: '',
  newPassword: '',
  confirmationNewPassword: '',
  receiveMessagesByCentralNotification: true,
  receiveMessagesByEmails: true,
};

export default () => ({
  isLoadingProfile: false,
  fieldErrors: {
    actualPassword: [],
    newPassword: [],
  },
  isFormDataDirty: false,
  originalProfile: { ...form },
  profile: { ...form },
  initialState: {
    name: '',
    email: '',
    actualPassword: '',
    newPassword: '',
    confirmationNewPassword: '',
    receiveMessagesByCentralNotification: true,
    receiveMessagesByEmails: true,
  },
});
