import Vue from 'vue';
import router from '@/router';
import { getCookie, goToRoute, setCookie } from '@zitrus/utils-service';
import { updateUserProfileService } from '@/views/user/service';
import { loadUserProfileService, refreshTokenApi, saveWorkspaceAttributes } from '../service';

/*
  This mapped messages, came from backend (z-gestao), and if they change, we need to update this array.
 */
const updateUserProfileErrorsMap = {
  actualPassword: ['Senha atual está incorreta'],
  newPassword: [
    'A senha deve ter pelo menos 8 caracteres de comprimento.',
    'A senha deve conter pelo menos 1 caracteres em maiúscula.',
    'A senha deve conter pelo menos 1 caracteres numéricos.',
    'A senha deve conter pelo menos 1 caracteres especiais.',
  ],
};

const fieldNotDirty = ['actualPassword'];

const loadUserProfile = async ({ commit }, usuarioId) => {
  await loadUserProfileService({
    usuarioId,
    onSuccess: ({ data }) => {
      const profile = {
        ...data.usuario,
        receiveMessageNotificationCenter: data?.atributos?.attributes?.find(
          (item) => item.key === 'receiveMessageNotificationCenter',
        ),
        receiveMessageEmail: data?.atributos?.attributes?.find(
          (item) => item.key === 'receiveMessageEmail',
        ),
      };

      commit('GENERIC_MUTATION', {
        property: 'profile',
        payload: { ...profile },
      });
      commit('GENERIC_MUTATION', {
        property: 'originalProfile',
        payload: { ...profile },
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao buscar dados do perfil do usuário',
      );
    },
  });
};

const updateUserProfile = async ({ commit, getters }) => {
  const { payload } = getters;

  let payloadProfile = Object.assign({}, payload);
  delete payloadProfile.receiveMessageEmail;
  delete payloadProfile.receiveMessageNotificationCenter;

  const payloadAttributes = [payload.receiveMessageEmail, payload.receiveMessageNotificationCenter];

  await updateUserProfileService({
    payload: payloadProfile,
    onSuccess: async () => {
      commit('GENERIC_MUTATION', {
        property: 'fieldErrors',
        payload: { actualPassword: [], newPassword: [] },
      });

      await refreshTokenApi({
        payload: { refreshToken: await getCookie('refreshToken') },
        onSuccess: ({ data }) => {
          setCookie('token', data?.accessToken);
          setCookie('refreshToken', data?.refreshToken);
        },
      });

      await saveWorkspaceAttributes({
        usuarioId: payload.id,
        payload: payloadAttributes,
        onSuccess: () => {
          commit('RESET_STATE');
          Vue.$toast.success('Ação realizada com sucesso');

          if (router.currentRoute?.query?.application) {
            goToRoute(
              `/${router.currentRoute?.query?.application}/${router.currentRoute?.query?.view}`,
            );
          } else {
            router.push({ path: `/${router.currentRoute?.query?.view}` });
          }
        },
        onCustomError: () => {
          Vue.$toast.error('Ocorreu um erro ao salvar os atributos do perfil do usuário!');
        },
      });
    },
    onCustomError: ({ response }) => {
      if (Array.isArray(response.data?.detail)) {
        const newFieldsErrors = { actualPassword: [], newPassword: [] };

        response.data.detail.forEach((errorMsg) => {
          const key = Object.keys(updateUserProfileErrorsMap).find((k) =>
            updateUserProfileErrorsMap[k].includes(errorMsg),
          );

          if (key) {
            newFieldsErrors[key].push(errorMsg);
          }
        });

        commit('GENERIC_MUTATION', {
          property: 'fieldErrors',
          payload: newFieldsErrors,
        });
      }

      Vue.$toast.error('Ocorreu um erro ao salvar o perfil do usúario.');
    },
  });
};

const updateField = ({ commit, state }, { property, value }) => {
  const { originalProfile } = state;

  commit('UPDATE_FIELD', {
    property,
    value,
  });

  if (!fieldNotDirty.includes(property) && originalProfile[property] !== '') {
    commit('GENERIC_MUTATION', {
      property: 'isFormDataDirty',
      payload: originalProfile[property] !== value,
    });
  }
};

const updateFieldErrors = ({ commit, getters }, { property, value }) => {
  commit('GENERIC_MUTATION', {
    property: 'fieldErrors',
    payload: { ...getters.fieldErrors, [property]: value },
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadUserProfile,
  updateUserProfile,
  updateField,
  updateFieldErrors,
  resetState,
};
