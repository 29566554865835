const noPermissionPath = 'usuario-sem-permissao';

async function getBase64(file) {
  const base64 = await new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (result) => {
      resolve(result.target.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(file);
  });

  return base64;
}

const getInitials = (value) => {
  if (!value) {
    return null;
  }

  const valueSpplited = value?.split(' ');
  const firstPiece =
    valueSpplited[0] == null ? null : valueSpplited[0]?.substring(0, 1)?.toUpperCase();
  const lastPiece =
    valueSpplited.length <= 1 && valueSpplited[valueSpplited.length - 1] == null
      ? null
      : valueSpplited[valueSpplited.length - 1]?.substring(0, 1)?.toUpperCase();

  return `${firstPiece ?? ''}${lastPiece ?? ''}`;
};

const formatDate = (value) => {
  if (!value || value.length === 0) {
    return null;
  }

  const date = new Date(value);
  const formatedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  return formatedDate;
};

const canShowUnsavedDialog = (path) => !path.includes(noPermissionPath);

export { getBase64, getInitials, formatDate, canShowUnsavedDialog };
