const forms = {
  id: null,
  active: true,
  name: '',
  email: '',
  picture: '',
  accessPermissions: [{}],
};

export default () => ({
  users: [],
  applications: [],
  profiles: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingProfilesTable: false,
  loadingApplicationsTable: false,
  loadingAccessPermissisons: false,
  isSaving: false,
  isSavingAndNew: false,
  isFormDataDirty: false,
  fieldErrors: { email: [] },
  user: { ...forms },
  originalUser: { ...forms },
  initialState: {
    id: null,
    active: true,
    name: '',
    email: '',
    picture: '',
    accessPermissions: [{}],
  },
  emailNotValid: true,
  showExternalUserDialog: false,
  externalUser: {},
  isExternalUser: false,
  inUse: false,
  currentClientUserEnabled: false,
});
