import { api, zBffApi, zLicenceApi } from '@/api';
import { createCommandService } from '@/services';

const getUsers = async ({ type, clientId, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    method: 'get',
    uri: `${zBffApi}/usuarios-tenant/${clientId}/enabled/${type}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getUserById = ({ userId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${api}/user/${userId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createUserService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${api}/user`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteUserService = ({ id, onSuccess, onCustomError }) =>
  createCommandService({
    method: 'delete',
    uri: `${api}/user/${id}`,
    onSuccess,
    onCustomError,
  });

const updateUserService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${api}/user/${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateUserProfileService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${api}/user/update`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const putClientOnTokenService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${api}/login/add/client`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const loadBlockedUsersService = ({ clientId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${zBffApi}/usuarios-tenant/${clientId}?status=BLOQUEADO`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const loadExternalUsersService = ({ clientId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    uri: `${zBffApi}/usuarios-externos-tenant/${clientId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const validEmailService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'post',
    payload,
    uri: `${api}/user/validate/email`,
    config: {
      headers: {
        'Content-Type': 'text/plain',
      },
    },
    onSuccess,
    onCustomError,
    onFinally,
  });

const saveClientExternalUserService = ({
  payload: { clientId, userId, user, external },
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    payload: {
      ...user,
      external,
    },
    method: 'put',
    uri: `${zLicenceApi}/cliente/${clientId}/usuario/${userId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const isExternalUserService = ({
  userId,
  clientId,
  responsibleClientId,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${zLicenceApi}/cliente-usuario/${userId}/${clientId}/${responsibleClientId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateBlockedUserService = ({ userId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'put',
    uri: `${api}/user/enable/${userId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const updateExternalUserService = ({
  clientId,
  userId,
  payload,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${zLicenceApi}/cliente-usuario/${clientId}/alternar_ativo/${userId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getUsers,
  getUserById,
  createUserService,
  deleteUserService,
  updateUserService,
  updateUserProfileService,
  putClientOnTokenService,
  loadBlockedUsersService,
  loadExternalUsersService,
  validEmailService,
  saveClientExternalUserService,
  isExternalUserService,
  updateBlockedUserService,
  updateExternalUserService,
};
