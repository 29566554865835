import Vue from 'vue';
import { goToRoute, setCookie } from '@zitrus/utils-service';
import { putClientOnTokenService } from '@/views/user/service';
import { getCompanies, getLastCompanyAccessByUserId } from '@/views/company/service';
import { reloadUserPermissions } from '@/services/zpermission';
import { getUserAppsByClient } from '@/views/portal/service';
import router from '@/router';
import { emitEvent } from '@/events/custom-events-service';
import { formatUserApps } from '@/utils/clientApplicationsFormat';

const loadClients = async ({ commit }, { userId }) => {
  await getCompanies({
    query: { userId },
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'clients',
        value: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao atualizar client no token de usuário',
      );
    },
  });
};

const loadLastAccess = async ({ commit }, { userId }) => {
  await getLastCompanyAccessByUserId({
    userId,
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'lastAccessClients',
        value: data?.clientes,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao buscar últimos acessos do usuário',
      );
    },
  });
};

const loadClientApplications = async ({ state, commit }) => {
  commit('UPDATE_FIELD', {
    property: 'isLoadingApplications',
    value: true,
  });
  await getUserAppsByClient({
    clientId: state?.portal?.selectedClientId || Vue.prototype.getLoggedUser()?.clientId,
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'applications',
        value: formatUserApps(data),
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar aplicações do cliente');
    },
    onFinally: () => {
      commit('UPDATE_FIELD', {
        property: 'isLoadingApplications',
        value: false,
      });
    },
  });
};

const setShowAllClients = ({ commit }, showAllClients) => {
  commit('UPDATE_FIELD', {
    property: 'showAllClients',
    value: showAllClients,
  });
};

const setSelectedClient = ({ commit }, clientId) => {
  commit('UPDATE_FIELD', {
    property: 'selectedClientId',
    value: clientId,
  });
};

const putClientOnToken = async ({ commit }, { clientId, clientName, refreshToken }) => {
  commit('UPDATE_FIELD', {
    property: 'isPutingClientOnToken',
    value: true,
  });

  await putClientOnTokenService({
    payload: { clientId, clientName, refreshToken },
    onSuccess: async ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'selectedClientId',
        value: clientId,
      });

      commit('UPDATE_FIELD', {
        property: 'selectedClientName',
        value: clientName,
      });

      await setCookie('token', data.accessToken);
      await setCookie('refreshToken', data.refreshToken);
      emitEvent('@zitrus/z-login/user-change-client', true);

      await router.push({ name: 'selecionar-aplicacao' });
    },
    onCustomError: ({ response }) => {
      commit('UPDATE_FIELD', {
        property: 'selectedClientId',
        value: null,
      });

      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(
        message ?? 'Ocorreu um erro de requisição ao atualizar client no token de usuário',
      );
    },
    onFinally: () => {
      commit('UPDATE_FIELD', {
        property: 'isPutingClientOnToken',
        value: false,
      });
    },
  });
};

const selectApplication = async ({ commit }, applicationKey) => {
  commit('UPDATE_FIELD', {
    property: 'isOpeningApplication',
    value: true,
  });

  await reloadUserPermissions({
    applicationKey,
    onSuccess: () => {
      goToRoute(`/${applicationKey}`);
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response?.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar permissões do usuário');
    },
    onFinally: () => {
      commit('UPDATE_FIELD', {
        property: 'isOpeningApplication',
        value: false,
      });
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadClients,
  loadLastAccess,
  loadClientApplications,
  setShowAllClients,
  putClientOnToken,
  setSelectedClient,
  selectApplication,
  updateField,
  resetState,
};
