import Vue from 'vue';
import router from '@/router';

import {
  getCompanies,
  getCompanyById,
  createCompanyService,
  deleteCompanyService,
  updateCompanyService,
} from '../service';

import { findCep, findCities, findStates } from '@/services/zdata';

const loadCompanies = async ({ commit }, query) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingTable',
    payload: true,
  });

  await getCompanies({
    query,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'companies',
        payload: data?.content,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalElements',
        payload: data?.totalElements,
      });

      commit('GENERIC_MUTATION', {
        property: 'totalPages',
        payload: data?.totalPages,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar empresas');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingTable',
        payload: false,
      });
    },
  });
};

const loadCompanyById = async ({ commit }, id) => {
  await getCompanyById({
    id,
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'company',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar empresa');
    },
  });
};

const createCompany = ({ getters, commit }, saveAndNew) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadCompany } = getters;

  createCompanyService({
    payload: {
      ...payloadCompany,
      address: {
        ...payloadCompany.address,
        postalCode: payloadCompany.address?.postalCode?.replace(/\D/g, ''),
      },
    },
    onSuccess: () => {
      router.push(
        !saveAndNew
          ? { name: 'companyQuery' }
          : { name: 'companyEdit', params: { id: payloadCompany?.id } },
      );

      Vue.$toast.success('Empresa criada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao salvar empresa');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const updateCompany = ({ getters, commit }, saveAndNew) => {
  commit('GENERIC_MUTATION', {
    property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
    payload: true,
  });

  const { payloadCompany } = getters;

  updateCompanyService({
    payload: payloadCompany,
    onSuccess: () => {
      !saveAndNew && router.push({ name: 'companyQuery' });

      Vue.$toast.success('Empresa alterada com sucesso.');

      commit('RESET_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao atualizar empresa');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: saveAndNew ? 'isSavingAndNew' : 'isSaving',
        payload: false,
      });
    },
  });
};

const deleteCompany = async ({ state, commit, dispatch }, { id, query }) => {
  await deleteCompanyService({
    id,
    onSuccess: () => {
      Vue.$toast.success('Empresa excluída com sucesso.');

      if (state.companies.length === 1) {
        router.replace({
          name: 'companyQuery',
        });
      }

      dispatch('loadCompanies', query);

      commit('RESET_FORM_STATE');
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao excluir empresa');
    },
  });
};

const findAddressByPostalCode = async ({ getters, commit }, cep) => {
  commit('GENERIC_MUTATION', {
    property: 'findingCep',
    payload: true,
  });

  const { payloadCompany } = getters;

  await findCep({
    cep,
    onSuccess: ({ data }) => {
      commit('UPDATE_FIELD', {
        property: 'address',
        value: {
          ...payloadCompany.address,
          street: data?.logradouro,
          district: data?.bairro,
          number: null,
          complement: data?.complemento,
          city: data?.localidade,
          state: data?.uf,
        },
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar cep');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'findingCep',
        payload: false,
      });
    },
  });
};

const findAllCities = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingCities',
    payload: true,
  });

  await findCities({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'cities',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar cidades');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingCities',
        payload: false,
      });
    },
  });
};

const findAllStates = async ({ commit }) => {
  commit('GENERIC_MUTATION', {
    property: 'loadingStates',
    payload: true,
  });

  await findStates({
    onSuccess: ({ data }) => {
      commit('GENERIC_MUTATION', {
        property: 'states',
        payload: data,
      });
    },
    onCustomError: ({ response }) => {
      const message = response.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;
      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao buscar estados');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'loadingStates',
        payload: false,
      });
    },
  });
};

const updateField = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD', {
    property,
    value,
  });
};

const updateFieldAddress = ({ commit }, { property, value }) => {
  commit('UPDATE_FIELD_ADDRESS', {
    property,
    value,
  });
};

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

export default {
  loadCompanies,
  loadCompanyById,
  createCompany,
  updateCompany,
  deleteCompany,
  findAddressByPostalCode,
  findAllCities,
  findAllStates,
  updateField,
  updateFieldAddress,
  resetState,
};
