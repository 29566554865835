const DEFAULT_BRAZILIAN_FORMAT = 'DD/MM/YYYY';
const DEFAULT_BRAZILIAN_DATE_TIME_FORMAT = `${DEFAULT_BRAZILIAN_FORMAT} HH:mm:ss`;
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}THH:mm:ss`;
const DEFAULT_DATE_TIME_FORMAT_FILTER = 'HH:mm';

export {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT_FILTER,
  DEFAULT_BRAZILIAN_FORMAT,
  DEFAULT_BRAZILIAN_DATE_TIME_FORMAT,
};
