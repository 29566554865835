import LgpdIndex from '../Index.vue';
import LgpdQuery from '../terms/Query.vue';
import AuditQuery from '../audit/Query.vue';

const fatherRouteName = 'lgpd';

export default [
  {
    path: '/lgpd',
    name: fatherRouteName,
    redirect: { name: fatherRouteName },
    component: LgpdIndex,
    children: [
      {
        path: 'termos',
        name: 'lgpd',
        meta: {
          title: 'Termos de uso e aviso de privacidade',
          subtitle: 'Aplicações',
          applicationKey: 'workspace',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        component: LgpdQuery,
      },
      {
        path: 'auditoria',
        name: 'auditoria',
        meta: {
          title: 'LGPD Auditoria',
          applicationKey: 'workspace',
          action: 'view',
          fatherRoute: fatherRouteName,
        },
        component: AuditQuery,
      },
      {
        path: 'edit/:id',
        name: 'edit-lgpd',
        component: () => import(/* webpackChunkName: "edit" */ '../terms/Edit.vue'),
        meta: {
          title: '',
          applicationKey: 'workspace',
          action: 'edit',
          backPage: 'termos',
          fatherRoute: fatherRouteName,
        },
      },
    ],
  },
];
