const forms = {
  name: '',
  key: '',
  path: '',
  enabled: true,
};

export default () => ({
  key: '',
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  loadingPermissions: false,
  informedApplication: {},
  isSaving: false,
  isSavingAndNew: false,
  application: { ...forms },
  applications: [],
  applicationPermissions: [],
  initialState: {
    name: '',
    key: '',
    path: '',
    enabled: true,
  },
});
