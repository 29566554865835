const form = {
  isLoadingClients: false,
  showAllClients: false,
  selectedClientId: null,
  selectedClientName: null,
  lastAccessClients: [],
  clients: [],
  isLoadingApplications: false,
  applications: [],
  isPutingClientOnToken: false,
  isOpeningApplication: false,
};

export default () => ({
  portal: { ...form },
  initialState: {
    isLoadingClients: false,
    showAllClients: false,
    selectedClientId: null,
    selectedClientName: null,
    lastAccessClients: [],
    clients: [],

    isLoadingApplications: false,
    applications: [],
    isPutingClientOnToken: false,
    isOpeningApplication: false,
  },
});
