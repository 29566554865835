import { zPermissionApi } from '@/api';
import { createCommandService } from '@/services';

const getProfiles = async ({ payload, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/perfil?isWebApp=true${
      payload != null && payload !== '' ? `&${payload}` : ''
    }`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getProfileById = async ({ id, onSuccess, onCustomError }) =>
  await createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/perfil/id/${id}`,
    onSuccess,
    onCustomError,
  });

const getProfilesByApplications = async ({ applications, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/perfil/${applications}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getProfilesUser = async ({ userId, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/usuario/${userId}/perfis`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const putProfilesUser = async ({ userId, payload, onSuccess, onCustomError, onFinally }) =>
  await createCommandService({
    payload,
    method: 'put',
    uri: `${zPermissionApi}/usuario/${userId}/perfil`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createProfileService = async ({
  payload,
  application,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  await createCommandService({
    payload,
    method: 'put',
    uri: `${zPermissionApi}/aplicacao/${application}/perfil`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const deleteProfileService = async ({ id, application, onSuccess, onCustomError }) =>
  await createCommandService({
    method: 'delete',
    uri: `${zPermissionApi}/aplicacao/${application}/perfil/${id}`,
    onSuccess,
    onCustomError,
  });

const updateProfileService = async ({
  payload,
  application,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  await createCommandService({
    payload,
    method: 'put',
    uri: `${zPermissionApi}/aplicacao/${application}/perfil?perfilId=${payload.id}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const getProfileTemplates = async ({ applicationId, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    method: 'get',
    uri: `${zPermissionApi}/aplicacao/${applicationId}/perfil/template/grouped`,
    onSuccess,
    onCustomError,
    onFinally,
  });

export {
  getProfiles,
  getProfileById,
  getProfilesByApplications,
  getProfilesUser,
  putProfilesUser,
  createProfileService,
  deleteProfileService,
  updateProfileService,
  getProfileTemplates,
};
