const initialStateCredentials = {
  username: null,
  password: null,
  credentialScope: null,
  accessTokenUrl: null,
  grantType: null,
  clientId: null,
  clientSecret: null,
  token: null,
  authType: null,
};

const initialStatePayload = {
  id: null,
  companyId: null,
  integrationType: null,
  endpoint: null,
  applicationId: null,
  integrationCredential: { ...initialStateCredentials },
};

const credentialFields = {
  API_KEY: [
    {
      key: 1,
      label: 'Token',
      property: 'token',
      type: 'input',
    },
  ],
  OAUTH2: [
    {
      key: 1,
      label: 'Scope',
      property: 'credentialScope',
      type: 'input',
    },
    {
      key: 2,
      label: 'Access token URL',
      property: 'accessTokenUrl',
      type: 'input',
    },
    {
      key: 3,
      label: 'Grant type',
      property: 'grantType',
      type: 'select',
      items: ['client_credentials'],
    },
    {
      key: 4,
      label: 'Client ID',
      property: 'clientId',
      type: 'input',
    },
    {
      key: 5,
      label: 'Client secret',
      property: 'clientSecret',
      type: 'input',
    },
  ],
};

export { initialStateCredentials, initialStatePayload, credentialFields };
