import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import toast from '@/plugins/toast';
import vuetify from './plugins/vuetify';
import instanceAxios from './config/axios';
import loader from 'vue-ui-preloader';
import singleSpaVue from 'single-spa-vue';
import { VueMaskDirective } from 'v-mask';
import { UserPlugin } from '@zitrus/utils-service';

export const MyProfileRoute = require('@/views/myProfile/router/index').default;

Vue.use(router);
Vue.use(UserPlugin);
Vue.use(loader);
Vue.use(toast);

Vue.router = router;

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;
Vue.prototype.$http = instanceAxios;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store,
  },
});

export const { mount } = vueLifecycles;
export const { bootstrap } = vueLifecycles;
export const { unmount } = vueLifecycles;
