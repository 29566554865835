const forms = {
  id: null,
  type: '',
  application: {},
  permissionsIds: [],
  enabled: true,
};

export default () => ({
  profiles: [],
  totalElements: 0,
  totalPages: 0,
  loadingTable: false,
  isSaving: false,
  isFormDataDirty: false,
  permissionToggles: {},
  profile: { ...forms },
  originalProfile: { ...forms },
  initialState: {
    id: null,
    type: '',
    application: {},
    permissionsIds: [],
    enabled: true,
  },
  permissionsList: [],
  originalPermissionsList: [],
  loadingProfileTemplates: false,
});
