const emitEvent = (name, data) => {
  dispatchEvent(
    new CustomEvent(name, {
      detail: data,
    }),
  );
};

const listenEvent = (name, cb) => {
  window.addEventListener(name, cb);
};

const removeEventListener = (name, cb, capture) => {
  window.removeEventListener(name, cb, capture);
};

export { emitEvent, listenEvent, removeEventListener };
